import React from 'react'
import { ComingSoonImg, DitinuslogoBg, NotFoundBG, NotFoundText } from 'src/assets'
import './styles.css'
const ComingSoon = () => {
    return (

        <section className='NotFoundSection'>
            <div className="container position-relative" >
                <div className="row align-items-center justify-content-center pt-5 pb-5" >
                    <div className=' img-fill d-flex flex-column  justify-content-center align-items-center '>
                        <img loading="lazy" className='NotFoundImgOne' src={NotFoundBG} alt="" />
                    </div>
                    <div className='d-flex flex-column gap-3 align-items-center justify-content-center mt-4'>
                        <img loading="lazy" className='NotFoundImgTwo' src={NotFoundText} alt="" />
                        <h3 className='text-center text-white p-0 m-0 NotFoundHeader'>Page Not Found</h3>
                        <h5 className='text-center text-white p-0 m-0 NotFoundDesc'>We couldn’t find the page you’re looking for.</h5>
                        <a
                            type="button"
                            href="/"
                            style={{ border: "none" }}
                            className="transition text-nowrap fill-btn outline-btn2 mt-5 iconBtn col-8 col-sm-8 col-md-6 col-lg-3 col-xl-3 rounded-5 d-flex justify-content-center"
                        >
                            Go To Homepage
                        </a>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default ComingSoon