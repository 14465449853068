import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
const Index = () => {
  const [activeSection, setActiveSection] = useState<string>(
    "collection-of-personal-information"
  );
  const observerRef = useRef<IntersectionObserver | null>(null);


  interface Section {
    id: string;
    title: string;
  }

  const sections: Section[] = [
    {
      id: "collection-of-personal-information",
      title: "Collection of Personal Information",
    },
    {
      id: "automatic-collection-of-information",
      title: "Automatic Collection of Information",
    },
    {
      id: "sources-of-personal-information",
      title: "Sources of Personal Information",
    },
    { id: "use-of-personal-information", title: "Use of Personal Information" },
    {
      id: "how-we-protect-your-information",
      title: "How We Protect Your Information?",
    },
    {
      id: "sharing-your-personal-information",
      title: "Sharing Your Personal Information",
    },
    { id: "data-storage-and-transfer", title: "Data Storage and Transfer" },
    {
      id: "retention-of-your-personal-data",
      title: "Retention of your Personal Data",
    },
    { id: "data-deletion-request", title: "Data Deletion Request" },
    {
      id: "data-request-from-law-enforcement-agency",
      title: "Data request from Law Enforcement Agency",
    },
    { id: "web-browser-cookies", title: "Web Browser Cookies" },
    {
      id: "marketing-advertisement-preference",
      title: "Marketing, Advertisement & Preference",
    },
    { id: "email-opts-out", title: "Email opts out" },
    {
      id: "compliance-with-childrens-privacy",
      title: "Compliance with Children’s Privacy",
    },
    { id: "choice-and-transparency", title: "Choice And Transparency" },
    { id: "your-rights-under-dpdp-act", title: "Your Rights under DPDP Act" },
    {
      id: "changes-to-this-privacy-policy",
      title: "Changes To This Privacy Policy",
    },
    { id: "contact-us", title: "Contact Us" },
  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 938);
    };

    // Call handleResize initially
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Create intersection observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: "-20% 0px -80% 0px",
      }
    );

    sections.forEach(({ id }) => {
      const element = document.getElementById(id);
      if (element) {
        observerRef.current?.observe(element);
      }
    });

    const updateActiveSection = () => {
      const visibleSection = sections.find(({ id }) => {
        const element = document.getElementById(id);
        if (!element) return false;

        const rect = element.getBoundingClientRect();
        return (
          rect.top >= -window.innerHeight * 0.2 &&
          rect.top < window.innerHeight * 0.8
        );
      });

      // Ensure visibleSection is defined before accessing its id
      if (visibleSection) {
        setActiveSection(visibleSection.id);
      }
    }
    updateActiveSection();

    window.addEventListener("scroll", updateActiveSection);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      window.removeEventListener("scroll", updateActiveSection);
    };
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // useEffect(() => {
  //   observerRef.current = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           setActiveSection(entry.target.id);
  //         }
  //       });
  //     },
  //     {
  //       rootMargin: "-20% 0px -80% 0px",
  //     }
  //   );

  //   Object.values(sectionRefs.current).forEach((ref) => {
  //     if (ref) observerRef.current?.observe(ref);
  //   });

  //   return () => {
  //     if (observerRef.current) {
  //       observerRef.current.disconnect();
  //     }
  //   };
  // }, []);

  // const scrollToSection = (id: string) => {
  //   sectionRefs.current[id]?.scrollIntoView({ behavior: "smooth" });
  // };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ditinustechnology.com/privacy-policy"
        />
        <title>Privacy Policy | Ditinus Technology</title>
      </Helmet>
      <div>
        <main className="bg-height4 bg-kpo-bpo blog-list1 career-page privacy_main pb-5">
          <section className="container">
            <div className="row justify-content-center align-items-center">
              <div>
                <div className="top-banner">
                  <h1 className="heading-01 text-center">Privacy Policy</h1>
                </div>

                <div
                  className="text-center text-white "
                  style={{ marginBottom: "30px" }}
                >
                  <h6>Last updated on Sunday, 01 December 2024</h6>
                  <h6>Place: Mohali, Punjab</h6>
                </div>
              </div>

              <div
                className="row container "

              >
                <div className="col ">
                  <div className="col-12  text-white text-start ">
                    <p style={{ fontSize: '18px' }} className="p-0 m-0 ">
                      Thank you for using{" "}
                      <Link to="/">https://ditinustechnology.com</Link>. This
                      digital portal including its website is a digital property
                      owned and operated by Ditinus Technology Private Limited.
                      Ditinus Technology Private Limited or any of its
                      affiliates or associate companies or subsidiaries
                      (hereinafter collectively referred as “Ditinus",
                      “Company”, “we”, “us”, or “our”), prescribes this Privacy
                      Policy (the “Privacy Policy” / “Policy”), last updated on
                      the date mentioned above, describes how we collect, use,
                      store and disclose your (hereinafter referred to as “You”,
                      “User(s)”, or “Your”) Personal Information when you visit
                      our website (collectively “platform”) or use available
                      services provided herein.
                      <br />
                      <br />
                      This electronic record is generated by a computer system
                      and does not require any physical or digital signatures.
                      By accessing{" "}
                      <Link to="/">https://ditinustechnology.com</Link> or using
                      our services, you are consenting to be bound by this User
                      Agreement. Please ensure that you read and understand all
                      the provisions of this document before you start using the
                      <Link to="/">https://ditinustechnology.com</Link>, as you
                      shall be bound by all the terms and conditions herein of
                      this electronic contract. If this document is not be in a
                      language that you understand, you shall contact Ditinus at
                      the email
                      <Link to="mailto:hr@ditinustechnology.com">
                        {" "}
                        hr@ditinustechnology.com.
                      </Link>
                      <br />
                      <br />
                    </p>
                    <p style={{ fontSize: '18px' }} className="p-0 m-0">
                      IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY,
                      PLEASE DO NOT ACCESS THIS APPLICATION OR AVAIL ANY OF THE
                      SERVICES BEING PROVIDED THEREIN.
                    </p>
                    <br />
                    <p style={{ fontSize: '18px' }} className="p-0 m-0">
                      More particularly, this Privacy Policy explains what
                      information of yours will be collected when you access the
                      Services through our Website, how such Personal
                      Information will be used, store, shared and how you can
                      control the collection, correction and/or deletion of your
                      Personal Information in pursuance of Information
                      Technology Act, 2000 and Digital Personal Data Protection
                      Act, 2023. We will not use or share your Personal
                      Information with anyone except as described in this
                      Privacy Policy. The use of information collected through
                      our portal shall be limited to the purposes under this
                      Privacy Policy and our Terms of Use (the “Terms”) to
                      users. Capitalised terms not specifically defined herein
                      shall have the same meaning as ascribed to them in the
                      Terms.
                      <br />
                      <br />
                      Supplemental clauses in the Privacy Policy or documents
                      that may be posted on our platform from time to time are
                      hereby expressly incorporated herein by reference. We
                      reserve the right, in our sole discretion, to make changes
                      or modifications to the Privacy Policy time to time. We
                      will alert you about any changes by updating the “Last
                      updated” date of this Privacy Policy, and you waive any
                      right to receive specific notice of each such change.
                      Please ensure that you check the applicable Terms of
                      Privacy Policy every time you use our platform so that you
                      understand which Terms apply. You will be subject to, and
                      will be deemed to have been made aware of and to have
                      accepted, the changes in any revised Privacy Policy by
                      your continued use of our portal after the date such
                      revised Privacy Policy are posted.
                    </p>
                    <br />
                    <p style={{ fontSize: '18px' }} className="p-0 m-0">
                      Questions or concerns? Reading this privacy notice will
                      help you understand your privacy rights and choices. If
                      you do not agree with our policies and practices, please
                      do not access our Platform including Website, App or our
                      Services. If you still have any questions or concerns,
                      please contact us at
                      <Link to="mailto:hr@ditinustechnology.com">
                        {" "}
                        hr@ditinustechnology.com.
                      </Link>
                    </p>
                    <br />
                  </div>

                  <div className="col-12 text-white">
                    <h3 id="Overview">SUMMARY OF THE KEY POINTS</h3>
                    <p className="p-0 m-0 ">
                      <span className="fw-bold me-2">
                        What personal information do we process?{" "}
                      </span>{" "}
                      When you visit, use, or navigate our Services, we may
                      process personal information depending on how you interact
                      with our portal and the Services, the choices you make,
                      and the products and features you use.
                    </p>
                    <p className="p-0 m-0 ">
                      <span className="fw-bold me-2">
                        Do we process any sensitive personal information?{" "}
                      </span>{" "}
                      We may process sensitive personal information, when
                      necessary, with your consent or as otherwise permitted by
                      applicable law.
                    </p>
                    <p className="p-0 m-0 ">
                      <span className="fw-bold me-2">
                        Do you receive any information from third parties?{" "}
                      </span>{" "}
                      We may receive information from public databases,
                      marketing partners, social media platforms, and other
                      outside sources.
                    </p>
                    <p className="p-0 m-0 ">
                      <span className="fw-bold me-2">
                        How do you process my information?{" "}
                      </span>{" "}
                      We process your information to provide, improve, and
                      administer our Services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent. We process your information only when we
                      have a valid legal reason to do so
                    </p>
                    <p className="p-0 m-0 ">
                      <span className="fw-bold me-2">
                        In what situations and with which parties do we share
                        personal information?{" "}
                      </span>
                      We may share information in specific situations and with
                      specific third parties.
                    </p>
                    <p className="p-0 m-0 ">
                      <span className="fw-bold me-2">
                        How do we keep your information safe?{" "}
                      </span>{" "}
                      We have organizational and technical processes and
                      procedures in place to protect your personal information.
                      However, no electronic transmission over the internet or
                      information storage technology can be guaranteed to be
                      100% secure, so we cannot promise or guarantee that
                      hackers, cybercriminals, or other unauthorized third
                      parties will not be able to defeat our security and
                      improperly collect, access, steal, or modify your
                      information.
                    </p>
                    <p className="p-0 m-0 ">
                      <span className="fw-bold me-2">
                        What are your rights?{" "}
                      </span>{" "}
                      Depending on where you are located geographically, the
                      applicable privacy law may mean you have certain rights
                      regarding your personal information.
                    </p>
                    <p className="p-0 m-0 ">
                      <span className="fw-bold me-2">
                        How do I exercise my rights?{" "}
                      </span>{" "}
                      The easiest way to exercise your rights is by sending an
                      email to us at:{" "}
                      <Link to="mailto:hr@ditinustechnology.com">
                        hr@ditinustechnology.com
                      </Link>{" "}
                      , or by contacting us. We will consider and act upon any
                      request in accordance with applicable data protection
                      laws.
                    </p>
                  </div>

                  <div className="col-12 text-white mt-3">
                    <h3 className="">Overview of the Policy</h3>
                    <div className="d-flex flex-row">
                      <p className="p-0 m-0 me-3">A.</p>
                      <p className="p-0 m-0 ">
                        <Link to="/">https://ditinustechnology.com/</Link> is a
                        digital property and operated by Ditinus Technology
                        Private Limited. We are committed to protecting and
                        respecting your Privacy. We do collect your personal
                        information and process your personal data in accordance
                        with the Information Technology Act, 2000 (also known as
                        ITA-2000, or the IT Act), Digital Personal Data
                        Protection Act, 2023 and other national and state laws
                        which relate the processing of your personal data.
                      </p>
                    </div>
                    <div
                      id="collection-of-personal-information"
                      className="d-flex flex-row "
                    >
                      <p className="p-0 m-0 me-3">B.</p>
                      <p className="p-0 m-0 me-1 ">
                        We collect your information in order to provide and
                        continually improve our services. Our Privacy Policy is
                        subject to change at any time without a notice, to make
                        sure you are aware of any changes, please review this
                        Policy periodically. All the Service Partners or
                        Independent Partners who are working with or for us, or
                        who have access to personal information, will be
                        expected to read and comply with this Policy. No
                        third-party may access or process sensitive personal
                        information held by us without having first entered into
                        a Confidentiality Agreement.
                      </p>
                    </div>
                  </div>

                  <div className="col-12 text-white ">
                    <div
                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 1.</h4>
                      <h4 className="p-0 m-0  me-5">
                        Collection of Personal Information
                      </h4>
                    </div>
                    <div className="d-flex flex-row justify-content-center text-WH  mt-3">
                      <p className="p-0 m-0 me-3 text-center">1.1. </p>
                      <p className="p-0 m-0 me-2">
                        We may collect personal identification information from
                        Users in a variety of ways, including, but not limited
                        to, when Users visit our platform, register on our
                        platform, purchase a service, and in connection with
                        other activities, services, features or resources we
                        make available on our platform. Users may be asked for
                        personally identifiable information (Such as your name
                        and email address) etc., We will collect personal
                        identification information from Users only if they
                        voluntarily submit such information to us. Users can
                        always refuse to supply personal identification
                        information, except that it may prevent them from
                        engaging in certain platform related activities. The
                        information we may collect via our platform depends on
                        the content and Services You Use.
                      </p>
                    </div>

                    <div className="d-flex flex-row justify-content-center text-WH  mt-3">
                      <p className="p-0 m-0 me-3 text-center"> 1.2. </p>
                      <p className="p-0 m-0 me-2">
                        Your Personal Data: We may ask you to provide us with
                        certain demographic and other required information when
                        you choose to avail our services. It may include, but is
                        not limited to:
                      </p>
                    </div>
                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3">1.2.1. </p>
                        <p className="p-0 m-0"> Email address</p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3">1.2.2. </p>
                        <p className="p-0 m-0 "> First name and Last name</p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3">1.2.3. </p>
                        <p className="p-0 m-0 ">Phone number</p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 1.2.4. </p>
                        <p className="p-0 m-0 ">
                          Address, State, Province, ZIP/Postal code, City
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 ">1.2.5. </p>
                        <p className="p-0 m-0"> Usage Data</p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 1.2.6.</p>
                        <p className="p-0 m-0 ">
                          Account details such as User ID, Password
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 1.2.7.</p>
                        <p className="p-0 m-0 ">Payment details</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row text-WH  mt-3">
                      <p className="p-0 m-0 me-3"> 1.3.</p>
                      <p className="p-0 m-0 ">
                        Non-Personal Identification Information: We may collect
                        non-personal identification information about Users
                        whenever they interact with our platform. Non-personal
                        identification information may include the browser name,
                        the type of computer and technical information about
                        Users' means of connection to our platform, such as the
                        operating system and the Internet service providers
                        utilized and other similar information.
                      </p>
                    </div>

                    <div className="d-flex flex-row text-WH mt-3">
                      <p className="p-0 m-0 me-3"> 1.4.</p>
                      <p className="p-0 m-0 ">
                        Data from Social Networks: Users information from the
                        Social Networking sites, such as Facebook, Google+,
                        Instagram, YouTube, Pinterest, Twitter, including your
                        name, your social network user name, location, gender,
                        birthdate, email address, profile picture, and public
                        data for contacts, if you connect your account to such
                        social networks. This information may also include the
                        contact information of anyone you invite to use and/or
                        join our platform.
                      </p>
                    </div>

                    <div className="d-flex flex-row text-WH mt-3">
                      <p className="p-0 m-0 me-3"> 1.5.</p>
                      <p className="p-0 m-0 ">
                        Geo-Location Information: We may request access or
                        permission to and track location-based information from
                        your mobile device, either continuously or while you are
                        using our platform, to provide location-based services.
                        If you wish to change our Access or Permissions, you may
                        do so in your device’s settings.
                      </p>
                    </div>

                    <div className="d-flex flex-row text-WH mt-3">
                      <p className="p-0 m-0 me-3"> 1.6. </p>
                      <p className="p-0 m-0 ">
                        Push Notifications: We may request to send you push
                        notifications regarding your account or our platform. If
                        you wish to opt-out from receiving these types of
                        communications, you may turn off in your Device’s
                        settings.
                      </p>
                    </div>

                    <div className="d-flex flex-row text-WH mt-3">
                      <p
                        id="automatic-collection-of-information"
                        className="p-0 m-0 me-3"
                      >
                        {" "}
                        1.7.{" "}
                      </p>
                      <p className="p-0 m-0 ">
                        Mobile Device Access & Data: We may request access or
                        permission to certain features from your mobile device,
                        including your mobile device’s storage. If you wish to
                        change our access or permission, you may do so in your
                        Device’s settings.
                      </p>
                    </div>
                    <div className="d-flex flex-row text-WH mt-3">
                      <p className="p-0 m-0 me-3"> 1.8. </p>
                      <p className="p-0 m-0 ">
                        Device information such as your mobile device ID number,
                        model, and manufacturer, version of your operation
                        system, phone number, country, location, and any other
                        data you choose to provide.
                      </p>
                    </div>
                  </div>

                  <div className="col-12 text-white">
                    <div
                      className="d-flex flex-row text-WH  mt-3 "

                    >
                      <h4 className="p-0 m-0 me-3"> 2.</h4>
                      <h4 className="p-0 m-0  me-5 ">
                        Automatic Collection of Information
                      </h4>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        2.1.{" "}
                      </p>
                      <p

                        className="p-0 m-0 me-2"
                      >
                        When You access our platform or use the Services by or
                        through a mobile device, We may collect certain usage
                        data automatically, Usage Data may include information
                        such as Your Device's Internet Protocol address (e.g. IP
                        address), browser type, browser version, the pages of
                        our Service that You visit, the time and date of Your
                        visit, the time spent on those pages, unique device
                        identifiers and other diagnostic data.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        2.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Derivative Data: Information our platform or Servers
                        automatically collect when you access our platform such
                        as your native actions that are integral to the
                        platform, including liking, replying to a message, as
                        well as other interactions with the platform and other
                        Users via server log files.
                      </p>
                    </div>
                    <div id="sources-of-personal-information" className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        2.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Additional details such as display image, alternate
                        mobile number, alternate email address might be
                        collected. In addition to the above information, for the
                        mobile application, other information required for
                        smooth mobile experience, including but not limited to
                        SMS access, location access, auto- OTP verification,
                        camera/gallery/contacts access, for various
                        functionalities within the platform have to be provided.
                      </p>
                    </div>
                    <div
                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}

                    >
                      <h4 className="p-0 m-0 me-3"> 3.</h4>
                      <h4 className="p-0 m-0  me-5">
                        Sources of Personal Information
                      </h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        3.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We obtain the categories of personal information listed
                        above from the following categories of sources:
                      </p>
                    </div>
                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 3.1.1.</p>
                        <p className="p-0 m-0">
                          {" "}
                          Directly from You. For example, from the forms You
                          complete on our platform or Services, preferences You
                          express or provide through our Services, or from Your
                          purchases on our Services.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 3.1.2. </p>
                        <p className="p-0 m-0 ">
                          Indirectly from You. For example, from observing Your
                          activity on our platform.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3">3.1.3. </p>
                        <p id="use-of-personal-information" className="p-0 m-0 ">
                          {" "}
                          Automatically from You. For example, through cookies
                          We or our Service Providers set on Your Device as You
                          navigate through our Service.
                        </p>
                      </div>
                      <div

                        className="d-flex flex-row  text-WH  mt-3"
                      >
                        <p className="p-0 m-0 me-3 "> 3.1.4. </p>
                        <p className="p-0 m-0 ">
                          From Service Providers. For example, third-party
                          vendors to monitor and analyse the use of our Service,
                          third-party vendors for payment processing, or other
                          third-party vendors that We use to provide the Service
                          to You.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 text-white">
                    <div
                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h3 className="p-0 m-0 me-3"> 4.</h3>
                      <h3 className="p-0 m-0  me-5">
                        Use of Personal Information
                      </h3>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        4.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Having accurate information about you permits us to
                        provide you with a smooth, efficient, and customized
                        Service experience. Specifically, our platform may
                        collect and use Users personal information to improve
                        Service; to Personalize User Experience; to improve our
                        Working; to Process Payments; to run a promotion,
                        contest, survey; to send periodic emails; Research and
                        Development; or Legal Requirements etc.
                      </p>
                    </div>

                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 4.2.1.</p>
                        <p className="p-0 m-0">
                          {" "}
                          To provide and maintain our Service, including to
                          monitor the usage of our Service.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 4.2.2.</p>
                        <p className="p-0 m-0 ">
                          To manage Your Account: to manage Your registration as
                          a user of the Service. The Personal Data You provide
                          can give You access to different functionalities of
                          the Service that are available to You as a registered
                          user.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 4.2.3. </p>
                        <p className="p-0 m-0 ">
                          {" "}
                          For the performance of a contract: the development,
                          compliance and undertaking of the purchase contract
                          for the products, items or services You have purchased
                          or of any other contract with Us through the Service.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.2.4. </p>
                        <p className="p-0 m-0 ">
                          To contact You: To contact You by email, telephone
                          calls, SMS, or other equivalent forms of electronic
                          communication, such as a mobile application's push
                          notifications regarding updates or informative
                          communications related to the functionalities,
                          products or contracted services, including the
                          security updates, when necessary or reasonable for
                          their implementation.
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.2.5. </p>
                        <p className="p-0 m-0 ">
                          To provide You with news, special offers, events and
                          general information about Services and events which we
                          offer that are similar to those that you have already
                          purchased or enquired about unless You have opted not
                          to receive such information.
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.2.6. </p>
                        <p className="p-0 m-0 ">
                          To manage Your requests: To attend and manage Your
                          requests to Us.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.2.7. </p>
                        <p className="p-0 m-0 ">
                          For business transfers: We may use Your information to
                          evaluate or conduct a merger, divestiture,
                          restructuring, reorganization, dissolution, or other
                          sale or transfer of some or all of Our assets, whether
                          as a going concern or as part of bankruptcy,
                          liquidation, or similar proceeding, in which Personal
                          Data held by Us about our Service users is among the
                          assets transferred.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.2.8. </p>
                        <p className="p-0 m-0 ">
                          For other purposes: We may use Your information for
                          other purposes, such as data analysis, identifying
                          usage trends, determining the effectiveness of our
                          promotional campaigns and to evaluate and improve our
                          Service, products, services, marketing and your
                          experience.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        4.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        More particularly, we may use your personal information
                        for the following purposes: We use the information for
                        the following purposes:
                      </p>
                    </div>

                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 4.3.1.</p>
                        <p className="p-0 m-0">
                          {" "}
                          To provide you functionality with the
                          extension/plug-ins and Services
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 4.3.2.</p>
                        <p className="p-0 m-0 ">
                          To analyse trends for how the Services and our
                          platform is used;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 4.3.3. </p>
                        <p className="p-0 m-0 ">
                          {" "}
                          To improve the Services and our platform;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.4. </p>
                        <p className="p-0 m-0 ">
                          To help personalize your experience of the Services
                          and the platform;
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.5. </p>
                        <p className="p-0 m-0 ">
                          To process or facilitate payments for Services;
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.6. </p>
                        <p className="p-0 m-0 ">
                          To enable features that allow you to share information
                          with other people;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.7. </p>
                        <p className="p-0 m-0 ">
                          To contact you in connection with your Services;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.8. </p>
                        <p className="p-0 m-0 ">
                          To perform internal operations necessary to provide
                          our Services, troubleshooting software bugs and
                          operational problems, to conduct data analysis,
                          testing, research, and to monitor and analyse usage
                          and activity trends;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.9. </p>
                        <p className="p-0 m-0 ">
                          To personalize the content and features you see when
                          you visit our platform;
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.10. </p>
                        <p className="p-0 m-0 ">
                          To respond to your questions and assist with problems
                          with our Services;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.11. </p>
                        <p className="p-0 m-0 ">
                          To assist you when you contact our customer support
                          services, including a) Direct your questions to the
                          appropriate customer support person, b) Investigate
                          and address your concerns, c) Monitor and improve our
                          customer support responses;
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 4.3.12. </p>
                        <p className="p-0 m-0 ">
                          For product development. This allows us to improve and
                          enhance the safety and security of our Services,
                          develop new features and products.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        4.4.
                      </p>
                      <p
                        id="how-we-protect-your-information"
                        className="p-0 m-0 me-2"
                      >
                        We may use the information collected to communicate with
                        you about products, services, promotions, studies,
                        surveys, news, updates, and events. We may also request
                        your feedback on the Services.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        4.5.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We may use your information for any other purpose
                        disclosed to you at the time we collect or receive the
                        information, or otherwise with your consent.
                      </p>
                    </div>

                    <div
                      className="d-flex flex-row text-WH  mt-3"

                    >
                      <h4 className="p-0 m-0 me-3">5.</h4>
                      <h4 className="p-0 m-0  me-5">
                        How We Protect Your Information?
                      </h4>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        5.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We adopt appropriate data collection, storage and
                        processing practices and security measures to protect
                        against unauthorized access, alteration, disclosure or
                        destruction of your personal information, username,
                        password, transaction information and data stored on our
                        platform.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        5.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Our teams work tirelessly to protect your information,
                        and to ensure the security and integrity of our
                        platform. We also have independent auditors assess the
                        security of our data storage and systems that process
                        financial information.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        5.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We take due care to protect Users data. Technical
                        measures are in place to prevent unauthorized access to
                        data and against accidental loss or destruction of, or
                        damage to, data. The employees who are dealing with the
                        data have been trained to protect the data form any
                        illegal or unauthorized usage. We work to protect the
                        security of your information during the transmission,
                        this allows sensitive information such as credit card
                        and numbers, and login credentials to be transmitted
                        securely.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        5.4.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We process Payment through Third Party Payment Gateway;
                        you shall be governed by their Policy as well as this
                        Privacy Policy. Also, we do not collect and store
                        information of any Debit or Credit Card of users.
                      </p>
                    </div>

                    <div
                      id="sharing-your-personal-information"
                      className="d-flex flex-row  text-WH  mt-3"
                    >
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        5.5.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We take reasonable steps to help protect your personal
                        information in an effort to prevent the loss, misuse,
                        and unauthorized access, disclosure alteration and
                        destruction. It is your responsibility to protect your
                        usernames and password to help prevent anyone from
                        accessing or abusing your accounts and services. You
                        should not use or reuse the same passwords you use with
                        other accounts as your password for our Services.
                      </p>
                    </div>

                    <div
                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 6.</h4>
                      <h4 className="p-0 m-0  me-5">
                        Sharing Your Personal Information
                      </h4>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        6.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We may share Your personal information in the following
                        situations:
                      </p>
                    </div>

                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 6.1.1.</p>
                        <p className="p-0 m-0">
                          {" "}
                          With Service Providers: We may share Your personal
                          information with Service Providers to monitor and
                          analyse the use of our Service, for payment
                          processing, and to contact You.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 6.1.2.</p>
                        <p className="p-0 m-0 ">
                          For business transfers: We may share or transfer Your
                          personal information in connection with, or during
                          negotiations of, any merger, sale of Company assets,
                          financing, or acquisition of all or a portion of Our
                          business to another company.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 6.1.3.</p>
                        <p className="p-0 m-0 ">
                          {" "}
                          With Affiliates: We may share Your information with
                          Our affiliates, in which case we will require those
                          affiliates to honor this Privacy Policy. Affiliates
                          include Our parent company and any other subsidiaries,
                          joint venture partners or other companies that We
                          control or that are under common control with Us.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 6.1.4. </p>
                        <p className="p-0 m-0 ">
                          With business partners: We may share Your information
                          with Our business partners to offer You certain
                          products, services or promotions.
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 6.1.5. </p>
                        <p className="p-0 m-0 ">
                          With other users: when You share personal information
                          or otherwise interact in the public areas with other
                          users, such information may be viewed by all users and
                          may be publicly distributed outside.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        6.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We may use third party service providers to help us
                        operate our business and our platform or administer
                        activities on our behalf, such as sending out
                        newsletters or surveys. We may share your information
                        with these third parties for those limited purposes
                        provided that you have given us your permission.
                      </p>
                    </div>
                    <div
                      id="data-storage-and-transfer"
                      className="d-flex flex-row  text-WH  mt-3"
                    >
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        6.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We may share users’ personal data if we believe it’s
                        required by applicable law, regulation, operating
                        license or agreement, legal process or governmental
                        request, or where the disclosure is otherwise
                        appropriate due to safety or similar concerns. This
                        includes sharing personal data with law enforcement
                        officials, public health officials, other government
                        authorities, or other third parties as necessary to
                        enforce our Terms of Service, user agreements, or other
                        policies; to protect our platform’s rights or property
                        or the rights, safety, or property of others; or in the
                        event of a claim or dispute relating to the use of our
                        services.
                      </p>
                    </div>

                    <div
                      className="d-flex flex-row text-WH  mt-3"

                    >
                      <h4 className="p-0 m-0 me-3"> 7. </h4>
                      <h4 className="p-0 m-0  me-5">
                        Data Storage and Transfer
                      </h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        7.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Your Personal Information and files including their
                        backups are processed and stored on our platform’s
                        servers and the servers of companies we hire to provide
                        services to Us. Your Personal Information may be
                        transferred across national borders because We may have
                        servers located outside India, and the companies we hire
                        to help us run our business may be located in different
                        countries around the world.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        7.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Your consent to this Privacy Policy followed by Your
                        submission of such information represents Your agreement
                        to that transfer.
                      </p>
                    </div>

                    <div
                      id="retention-of-your-personal-data"
                      className="d-flex flex-row  text-WH  mt-3"
                    >
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        7.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We will take all steps reasonably necessary to ensure
                        that Your data is treated securely and in accordance
                        with this Privacy Policy and no transfer of Your
                        Personal Data will take place to an organization or a
                        country unless there are adequate controls in place in
                        accordance with the data protection laws including the
                        security of Your data and other personal information.
                      </p>
                    </div>
                    <div
                      className="d-flex flex-row text-WH  mt-3"

                    >
                      <h4 className="p-0 m-0 me-3"> 8. </h4>
                      <h4 className="p-0 m-0  me-5">
                        Retention of your Personal Data
                      </h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        8.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We will retain Your Personal Data only for as long as is
                        necessary for the purposes set out in this Privacy
                        Policy. We will retain and use Your Personal Data to the
                        extent necessary to comply with our legal obligations
                        (for example, if we are required to retain your data to
                        comply with applicable laws), resolve disputes, and
                        enforce our legal agreements and policies.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        8.2.
                      </p>
                      <p id="data-deletion-request" className="p-0 m-0 me-2">
                        We retain the Personal Information collected where an
                        ongoing legitimate business requires retention of such
                        Personal Information. In the absence of a need to retain
                        your Personal Information as specified herein, we will
                        either delete or aggregate your Personal Information or,
                        if this is not possible then We will securely store your
                        Personal Information and isolate it from any further
                        processing until deletion is possible.
                      </p>
                    </div>
                    <div

                      className="d-flex flex-row  text-WH  mt-3"
                    >
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        8.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We will also retain Usage Data for internal analysis
                        purposes. Usage Data is generally retained for a shorter
                        period of time, except when this data is used to
                        strengthen the security or to improve the functionality
                        of Our Service, or We are legally obligated to retain
                        this data for longer time periods.
                      </p>
                    </div>
                    <div
                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 9. </h4>
                      <h4 className="p-0 m-0  me-5">Data Deletion Request</h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "id="data-request-from-law-enforcement-agency"
                      >
                        9.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We respect user’s privacy; Users may request deletion of
                        their data at any time by sending an email to us.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row  text-WH  mt-3"
                    >
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        9.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Following a data deletion request, we delete the user’s
                        data, unless they must be retained due to legal or
                        regulatory requirements, for purposes of safety,
                        security, and fraud prevention, or for completing the
                        transaction or because of an issue relating to the
                        user’s account such as an outstanding credit or an
                        unresolved claim or dispute. Because we are subject to
                        legal and regulatory requirements, this generally means
                        that we retain their account and data for a minimum of 5
                        (five) years after a deletion request.
                      </p>
                    </div>

                    <div
                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 10. </h4>
                      <h4 className="p-0 m-0  me-5">
                        Data request from Law Enforcement Agency
                      </h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        10.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We do not sell, trade, or otherwise transfer your
                        Personal Information except in accordance with this
                        Policy. We do not share any information that directly
                        identifies you with any third party, except in the
                        following limited cases:
                      </p>
                    </div>

                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 10.1.1.</p>
                        <p className="p-0 m-0">
                          {" "}
                          As required to comply with applicable law or
                          regulation, or to comply with law enforcement;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 10.1.2.</p>
                        <p className="p-0 m-0 ">
                          To respond to claims and/or legal process;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 10.1.3.</p>
                        <p className="p-0 m-0 ">
                          {" "}
                          To protect our rights or our property, or to enforce
                          our Terms of Service;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 10.1.4. </p>
                        <p className="p-0 m-0 ">
                          To help ensure the safety of the public or an
                          individual;
                        </p>
                      </div>

                      <div id="web-browser-cookies" className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 10.1.5. </p>
                        <p className="p-0 m-0 ">
                          To prevent behaviour that is (or that we think may be)
                          illegal or unethical;
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 10.1.6. </p>
                        <p className="p-0 m-0 ">
                          With your consent, or at your request or direction; or
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3 "> 10.1.7. </p>
                        <p className="p-0 m-0 ">
                          As otherwise set forth in this Policy.
                        </p>
                      </div>
                    </div>

                    <div

                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 11. </h4>
                      <h4 className="p-0 m-0  me-5">Web Browser Cookies</h4>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        11.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Cookies are small text files that are stored on browsers
                        or devices by apps, online media, and advertisements.
                        <Link to="/"> https://ditinustechnology.com/</Link> uses
                        cookies and similar technologies for purposes such as
                        authenticating users; remembering user preferences and
                        settings; determining the popularity of content;
                        delivering and measuring the effectiveness of
                        advertising campaigns; analysing site traffic and
                        trends, and generally understanding the online and
                        interests of people who interact with our services.
                      </p>
                    </div>
                    <div id="marketing-advertisement-preference" className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        11.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We may use "cookies" to enhance User experience. User's
                        web browser places cookies on their hard drive for
                        record-keeping purposes and sometimes to track
                        information about them. Users may choose to set their
                        web browser to refuse cookies, or to alert you when
                        cookies are being sent. If they do so, note that some
                        parts of the App may not function properly.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 12. </h4>
                      <h4 className="p-0 m-0  me-5">
                        {" "}
                        Marketing, Advertisement & Preference
                      </h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        12.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We use your personal information to send you marketing
                        by post, telephone, social media platforms, email, SMS,
                        application notifications and silent notifications to
                        track installation status of the application. We may
                        also use your personal information to carry out online
                        paid marketing activities in the nature of retargeting.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        12.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We may permit certain third-party companies to help us
                        tailor advertising that we think may be of interest to
                        users and to collect and use other data about user
                        activities. These companies may deliver ads that might
                        place cookies and otherwise track user behavior
                      </p>
                    </div>
                    <div id="email-opts-out" className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        12.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We also use technology to help us understand your
                        personal preferences and interests so that we can send
                        recommendations and marketing communications that are
                        likely to be of more interest to you. Using technology
                        in this way is sometimes known as profiling.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 13. </h4>
                      <h4 className="p-0 m-0  me-5"> Email opts out</h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        13.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Emails and Communications – If you no longer wish to
                        receive correspondence, emails, or other communications
                        from us, you may opt-out any time by:
                      </p>
                    </div>

                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 13.1.1.</p>
                        <p className="p-0 m-0">
                          {" "}
                          Noting your preference at the time you provide your
                          information to us.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 13.1.2. </p>
                        <p className="p-0 m-0 ">
                          Contacting us using the contact information provided
                          below.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        13.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        And if you no longer wish to receive correspondence,
                        emails, or other communications from third parties, you
                        are responsible for contacting the third party directly.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        13.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        Users may opt in/opt out from marketing notifications,
                        Live location sharing, updates and news received from
                        our platform by checking their device settings at any
                        time.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        13.4.
                      </p>
                      <p id="compliance-with-childrens-privacy" className="p-0 m-0 me-2">
                        If you wish to unsubscribe from emails sent by us, you
                        can also do so at any time by clicking on the
                        "unsubscribe" link that appears in all emails and by
                        adjusting the settings on your device to turn off
                        notifications from Ditinus. Otherwise, you can always
                        contact us to update your contact preferences. You may
                        also object to profiling relating to direct marketing by
                        contacting us at details given in Contact Us clause.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 14. </h4>
                      <h4 className="p-0 m-0  me-5">
                        {" "}
                        Compliance with Children’s Privacy{" "}
                      </h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        14.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We recognize the importance of children's safety and
                        privacy. For that reason, we never collect or maintain
                        information at our platform from those we actually know
                        are under 18 years of age, and no part of our platform
                        is structured to attract anyone under the age of 18.
                      </p>
                    </div>

                    <div id="choice-and-transparency" className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "

                      >
                        14.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We are very sensitive to privacy issues regarding
                        children. If a parent or guardian becomes aware that his
                        or her child has provided us with Personal Information,
                        he or she should write to us at the details given in the
                        Contact Us clause.
                      </p>
                    </div>

                    <div
                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 15. </h4>
                      <h4 className="p-0 m-0  me-5"> Choice And Transparency</h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        15.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        We enable users to access and/or control data that our
                        platform collects, including through:
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        15.2.
                      </p>
                      <p className="p-0 m-0 me-2">Privacy Settings –</p>
                    </div>

                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 15.2.1.</p>
                        <p className="p-0 m-0">
                          {" "}
                          Users can enable/disable our platform to collect
                          location data from their mobile devices through their
                          device settings, which can be accessed via the setting
                          Privacy Location menu.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 15.2.2. </p>
                        <p className="p-0 m-0 ">
                          Users can enable/disable our platform to share their
                          real-time location data from their mobile devices with
                          their drivers through their device settings, which can
                          be accessed via the Setting Privacy Location menu.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        15.3.
                      </p>
                      <p className="p-0 m-0 me-2">Device Permissions –</p>
                    </div>

                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 15.3.1. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Most mobile device platforms (iOS, Android, etc.) have
                          defined certain types of device data that apps cannot
                          access without the device owner’s permission, and
                          these platforms have different methods for how that
                          permission can be obtained. Please check the available
                          settings on your device or check with your provider.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "   id="your-rights-under-dpdp-act"
                      >
                        15.4.
                      </p>
                      <p className="p-0 m-0 me-2">
                        {" "}
                        Your Personal Information is extremely important to us.
                        Your information resides on a secure server that only
                        selected personnel have access to. Promotional and
                        referral codes are random alphanumeric sequences that
                        are encrypted. In addition to our precautions, it is
                        important that you do not share your password with
                        anyone. Although we try our best to protect your
                        information, we cannot eliminate security risks
                        associated with Personal Information.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 16. </h4>
                      <h4 className="p-0 m-0  me-5">
                        {" "}
                        Your Rights under DPDP Act
                      </h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        16.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        {" "}
                        You may exercise Your rights of access, rectification,
                        cancellation and opposition by contacting Us. Please
                        note that we may ask You to verify Your identity before
                        responding to such requests. If You make a request, we
                        will try our best to respond to You as soon as possible.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        16.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        {" "}
                        Please Note, we may process Personal Data under the
                        following conditions:
                      </p>
                    </div>

                    <div style={{ paddingLeft: "38px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 16.2.1. </p>
                        <p className="p-0 m-0">
                          {" "}
                          If you have given your consent;
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 16.2.2. </p>
                        <p className="p-0 m-0"> Performance of a contract;</p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 16.2.3. </p>
                        <p className="p-0 m-0"> Legal obligations;</p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 16.2.4. </p>
                        <p className="p-0 m-0">
                          {" "}
                          To protect your vital interests;
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 16.2.5. </p>
                        <p className="p-0 m-0">
                          {" "}
                          In Public and Legitimate Interests.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        16.3.
                      </p>
                      <p id="changes-to-this-privacy-policy" className="p-0 m-0 me-2">
                        {" "}
                        Your Rights under the DPDP: we undertake to respect the
                        confidentiality of Your Personal Data and to guarantee
                        You can exercise Your rights. You have the right under
                        this Privacy Policy, and by law if You are within the
                        Indian territory, to: Request access to Your Personal
                        Data; Request correction of the Personal Data; Object to
                        processing of Your Personal Data; Request erasure of
                        Your Personal Data; Request the transfer of Your
                        Personal Data; Withdraw Your consent.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        16.4.
                      </p>
                      <p className="p-0 m-0 me-2">
                        {" "}
                        In any case, we will gladly help to clarify the specific
                        legal basis that applies to the processing, and in
                        particular whether the provision of Personal Data is a
                        statutory or contractual requirement, or a requirement
                        necessary to enter into a contract.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 17. </h4>
                      <h4 className="p-0 m-0  me-5">
                        {" "}
                        Changes To This Privacy Policy
                      </h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        17.1.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        {" "}
                        WE MAY OCCASIONALLY UPDATE THIS PRIVACY POLICY.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  " id="contact-us"
                      >
                        17.1.2.
                      </p>
                      <p className="p-0 m-0 me-2">
                        {" "}
                        We have the discretion to update this privacy policy at
                        any time. When we do, we will send you an email. We
                        encourage Users to frequently check this page for any
                        changes to stay informed about how we are helping to
                        protect the personal information we collect. You
                        acknowledge and agree that it is your responsibility to
                        review this privacy policy periodically and become aware
                        of modifications.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        17.1.3.
                      </p>
                      <p className="p-0 m-0 me-2">
                        {" "}
                        Use of our services after an update constitutes consent
                        to the updated Privacy Policy to the extent permitted by
                        law.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row text-WH  mt-3"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      <h4 className="p-0 m-0 me-3"> 18. </h4>
                      <h4 className="p-0 m-0  me-5"> Contact Us</h4>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        18.1.
                      </p>
                      <p className="p-0 m-0 me-2">
                        {" "}
                        If you have any questions about this Privacy Policy, the
                        practices of our platform, your dealings with our
                        platform and you would like to ask about or complain
                        about how we process your personal information, please
                        contact us for support, or mail us at the addresses
                        below.
                      </p>
                    </div>

                    <div style={{ paddingLeft: "38px", marginBottom: "50px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 ">
                          {" "}
                          Ditinus Technology Private Limited{" "}
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-1">
                        <p className="p-0 m-0">
                          {" "}
                          <Link className="text-wraps" to="mailto:grievance.officer@ditinustechnology.com">
                            {" "}
                            grievance.officer@ditinustechnology.com
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {!isMobile && (
                  <div className="col-4">
                    <nav className="text-white w-full position-sticky privacy_link">
                      <div>
                        {/* <ul className="list-unstyled text-white">
                        {sections.map(({ id, title }) => (
                          <li key={id}>
                            <button
                              style={{ fontSize: "14px" }}
                              onClick={() => scrollToSection(id)}
                              className={`w-100 text-start btn   ${
                                activeSection === id
                                  ? "text-primary"
                                  : "text-white"
                              }`}
                            >
                              {title}
                            </button>
                          </li>
                        ))}
                      </ul> */}


                        <Container fluid className="vertical-timeline-terms">
                          <Col className="timeline-nav">
                            <Nav className="flex-column">
                              {sections?.map((item, index) => (
                                <Nav.Link
                                  key={item?.id}
                                  ref={(ref) => {
                                    if (activeSection === item?.id && ref) {
                                      ref.scrollIntoView({
                                        behavior: "smooth",
                                        block: "nearest",
                                      });
                                    }
                                  }}
                                  className={activeSection === item?.id ? "active" : ""}
                                  onClick={() => scrollToSection(item?.id)}
                                >
                                  <span className="timeline-number me-2">{index + 1}</span>
                                  <span className="me-3">{item?.title}</span>
                                </Nav.Link>
                              ))}
                            </Nav>
                          </Col>
                        </Container>


                      </div>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default Index;
