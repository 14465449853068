import {
  bpoicon,
  clientImg1,
  Founder,
  imagekpo2,
  OutSourceCardImg,
  OutsourceIMG2,
  OutsourceIMG3,
  plushsiconimg,
  quote,
  star,
  Vector1,
  Vector10,
  Vector11,
  Vector12,
  Vector13,
  Vector14,
  Vector15,
  Vector16,
  Vector17,
  Vector18,
  Vector19,
  Vector2,
  Vector3,
  Vector4,
  Vector5,
  Vector6,
  Vector7,
  Vector8,
} from "src/assets";
import { Helmet } from "react-helmet";
import OutsourceForm from "../../Components/OutsourceForm";
import Marquee from "react-fast-marquee";
import { useState } from "react";
import { reviewData } from "../KpoBpo/data";
const Index = () => {
  const [isPaused, setIsPaused] = useState(false);
  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href="https://ditinustechnology.com/business-process-services"
        />
        <title>Best BPO, Business Process Outsourcing company in India</title>
        <meta
          name="description"
          content="Ditinus Technology provides business process outsourcing services in India. Streamline operations and drive growth with tailored, efficient BPO solutions."
        />
      </Helmet>
      <main className="bg-height4 bg-kpo-bpo">
        <section
          className="subBanner2 "
          style={{ marginTop: "80px", marginBottom: "80px" }}
        >
          <div className="container mt-md-5 ">
            <div className="row justify-content-center align-items-center ">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center mt-5 d-flex align-items-center flex-column">
                <h1 className="heading-223 col-12 col-md-12 col-sm-12 col-lg-11 col-xl-11">
                  Skyrocket Productivity, Slash Costs! <br />
                  Let Our Business Process Services Power Up Your Brand Game
                </h1>
                <p className="mt-3 heading-224 col-11  col-md-9 col-1g-8 col-xl-9 ">
                  Did You Know? 76% of Businesses Slash Operational Costs and
                  Speed Up Time-to-Market with Business Process Services!
                </p>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-5 mb-5 ">
                <a
                  href="#contact-form"
                  type="button"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="out-source-banner ">
          <div className="container out-source-fluid ">
            <div className="row justify-content-between ">
              <div className="col-12 col-sm-12 col-md-12 col-xl-8 col-lg-8 text-start d-flex flex-column justify-content-center">
                <h2 className="heading-225 ">
                  Don’t let high costs hold you back!
                </h2>
                <p className="heading-226">
                  Reach out to us today and discover how our talented developers
                  in India can be a game-changer for your business!
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4 d-flex justify-content-start justify-content-sm-start justify-content-md-start  justify-content-lg-end  justify-content-xl-end align-items-center">
                <a
                  href="#contact-form"
                  type="button"
                  className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn px-5"
                >
                  Contact Us
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="out-source-sections ">
          <div className="container">
            <div className="justify-content-between" data-aos="fade-up">
              <div className="img-shadow4">
                <img
                  loading="lazy"
                  src={imagekpo2}
                  className="w-100"
                  alt="line"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="out-source-sections ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-10 col-md-12 col-12 text-center d-flex flex-column justify-content-center align-items-center ">
                <h2 className="heading-228 p-o m-0 col-10   col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  Let’s Get Down to Business! Explore Our Business Process
                  Services for The Maximum Impact
                </h2>
                <p className="heading-227 col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 p-0 m-0 mt-3">
                  Crush the competition and future-proof your business with our
                  Business Process Solutions We're all about powering your
                  success today and setting you up for an even better tomorrow!{" "}
                </p>
              </div>
            </div>

            <div className="row col-12 d-flex justify-content-center justify-content-lg-between  justify-content-xl-between  p-0 m-0 mt-5">
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 mb-5 mb-sm-0 mb-lg-0 mb-xl-0 mb-md-0 mt-sm-0 mt-lg-0 mt-xl-0 mt-md-0">
                <div
                  className="item-source2 col-12 d-flex flex-column justify-items-start"
                  data-aos="fade-left"
                >
                  <h2>1</h2>
                  <div className="text-item2 text-start  d-flex flex-column gap-3 ">
                    <h3 className="fw-bold">Inbound Services</h3>
                    <p className="text-white text-start col-12  ">
                      Your customers deserve the best, and we’re here to
                      deliver! Our tailored inbound business process services
                      are designed to meet the unique needs of every client.
                      Explore our service suite and unlock maximum value today!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 m-0 img-fluid rounded-5  mt-5 mt-sm-5 mt-xl-0 mt-lg-0 mt-md-5">
                <img
                  className="img-fluid w-100 h-100 "
                  src={OutsourceIMG2}
                  alt=""
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="bg-color-box2"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                      <img
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector1}
                        alt="line"
                      />
                    </div>
                    <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                      <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">
                        Customer Support
                      </h3>
                      <p className="text-white p-0 m-0 mt-3 fw-light">
                        Our dedicated customer support team offers around the
                        clock assistance, delivering solutions that go above and
                        beyond expectations. We ensure your customers feel
                        heard, valued, and fully supported at every step. From
                        answering inquiries to resolving issues and offering
                        expert product guidance, we create smooth experiences
                        that drive customer loyalty and satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box2"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                      <img
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector2}
                        alt="line"
                      />
                    </div>
                    <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                      <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">
                        Chat Support
                      </h3>
                      <p className="text-white p-0 m-0 mt-3 fw-light">
                        Say goodbye to delayed customer support with our inbound
                        services. Our live chat support ensures instant
                        assistance, providing quick responses and efficient
                        problem resolution. No matter the query, we deliver a
                        prompt, personalized experience that meets your
                        customers’ needs, every time.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box2"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                      <img
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector3}
                        alt="line"
                      />
                    </div>
                    <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                      <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">
                        Email Support
                      </h3>
                      <p className="text-white p-0 m-0 mt-3 fw-light">
                        Want to make a lasting impact on your audience? With our
                        email support services, you can deliver prompt, clear
                        responses to customer queries, complaints, and feedback.
                        We focus on accuracy and attention to detail, ensuring
                        every email interaction leaves a positive and lasting
                        impression on your customers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row col-12 d-flex justify-content-center p-0 m-0 mt-5">
              <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8  mb-5 mt-10 mb-sm-0 mb-lg-0 mb-xl-0 mb-md-0 mt-sm-0 mt-lg-0 mt-xl-0 mt-md-0">
                <div
                  className="item-source2 col-12 d-flex flex-column justify-items-start"
                  data-aos="fade-left"
                >
                  <h2>2</h2>
                  <div className="text-item2 text-start  d-flex flex-column gap-3 ">
                    <h3 className="fw-bold">Outbound Services</h3>
                    <p className="text-white text-start col-12  ">
                      Expand your business reach and effectively connect with
                      your target audience through our powerful suite of
                      outbound business process services! Unlock new
                      opportunities and drive growth every step of the way!
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 m-0 img-fluid rounded-5  mt-5 mt-sm-5 mt-xl-0 mt-lg-0 mt-md-5">
                <img
                  className="img-fluid w-100 h-100 "
                  src={OutsourceIMG3}
                  alt=""
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="bg-color-box2"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                      <img
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector4}
                        alt="line"
                      />
                    </div>
                    <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                      <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">
                        Sales
                      </h3>
                      <p className="text-white p-0 m-0 mt-3 fw-light">
                        Reach your targets and optimize your sales prospects
                        with ease with our outbound sales services. We use
                        proven strategies to highlight your brand’s core
                        competency, build meaningful relationships, and close
                        deals, ensuring consistent revenue growth and long-term
                        client satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box2"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                      <img
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector5}
                        alt="line"
                      />
                    </div>
                    <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                      <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">
                        Lead Generation and Qualification
                      </h3>
                      <p className="text-white p-0 m-0 mt-3 fw-light">
                        Our expert lead generation services are designed to
                        deliver only the highest-quality leads to you. Our
                        rigorous filtering process ensures that only premium
                        clients with strong conversion potential reach your
                        sales team, maximizing your revenue opportunities.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box2"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                      <img
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector6}
                        alt="line"
                      />
                    </div>
                    <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                      <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">
                        Customer Retention and Win-Back Campaigns
                      </h3>
                      <p className="text-white p-0 m-0 mt-3 fw-light">
                        Every contact matters, and every customer counts. Our
                        strategic customer retention and win-back campaigns
                        focus on unlocking the potential of even the most lost
                        leads. We build loyalty and reconnect with lapsed
                        customers, transforming them into repeat buyers.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box2"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                      <img
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector7}
                        alt="line"
                      />
                    </div>
                    <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                      <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">
                        Surveys and Feedback Collection
                      </h3>
                      <p className="text-white p-0 m-0 mt-3 fw-light">
                        Gain valuable insights directly from your customers with
                        our surveys and feedback collection outbound services.
                        By understanding their needs and preferences we help you
                        make data-driven decisions, improve satisfaction, and
                        continuously enhance your product or service offerings.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-color-box2"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div className="row">
                    <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                      <img
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector8}
                        alt="line"
                      />
                    </div>
                    <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                      <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">
                        Appointment Setting
                      </h3>
                      <p className="text-white p-0 m-0 mt-3 fw-light">
                        We make appointment setting easy by scheduling qualified
                        meetings with your clients. Our organized approach
                        allows your team to spend less time on coordination and
                        more time on building meaningful conversations,
                        resulting in closed deals and better relationships.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="out-source-sections">
          <div className="container mt-5">
            <div className="row col-12 p-0 m-0 ">
              <div className="col-12 col-md-12 col-xl-12 col-lg-12 col-sm-12 d-flex flex-column justify-content-center gap-3">
                <h2 className="heading-231 text-start p-0 m-0 col-12">
                  We Offer Custom Solutions for Every Industry!
                </h2>
                <p className="text-white p-0 m-0 text-start heading-232 fw-light col-11">
                  Our Business Process Solutions are not confined to a single
                  industry. Our expertise spans several key sectors, driving
                  innovation and sustainable growth across the board.
                </p>
              </div>
              <div className="col-12">
                <h3 className="text-white p-0 m-0 card-middle-test text-uppercase text-start py-3">
                  Industries we Serve!
                </h3>
              </div>

              <div className="col-12 col-md-12 mt-5 mt-sm-5 mt-lg-0 mt-xl-0 mt-md-5 col-xl-12 col-lg-12 col-sm-12 p-0 m-0 d-flex flex-row flex-sm-row flex-lg-column flex-xl-col flex-md-row align-items-center justify-content-evenly gap-5 gap-sm-5 gap-md-5 gap-lg-5 gap-xl-5">
                <div className="col-6 col-sm-6 col-lg-12 col-xl-12 col-md-6 row d-flex flex-column flex-sm-column flex-lg-row flex-xl-row flex-md-column gap-md-4 gap-3 gap-lg-5 gap-xl-5 gap-sm-3   align-items-end">
                  <div className="col-5  out-source-card1" data-aos="fade-left">
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-right"
                        loading="lazy"
                        className="img-fulid bpo-vector"
                        src={Vector13}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center">
                        Agriculture
                      </h4>
                    </div>
                  </div>
                  <div className="col-5  out-source-card5" data-aos="fade-left">
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-right"
                        loading="lazy"
                        className="img-fulid bpo-vector"
                        src={Vector11}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center">
                        Logistics
                      </h4>
                    </div>
                  </div>
                  <div className="col-5  out-source-card5">
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-left"
                        loading="lazy"
                        className="img-fulid bpo-vector"
                        src={Vector10}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center">
                        Travel
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-5  out-source-card5"
                    data-aos="fade-right"
                  >
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-left"
                        loading="lazy"
                        className="img-fulid bpo-vector"
                        src={Vector12}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center">
                        Information Technology
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-5  out-source-card2"
                    data-aos="fade-right"
                  >
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-left"
                        loading="lazy"
                        className="img-fulid bpo-vector"
                        src={Vector14}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center">
                        Healthcare &<br /> Pharmaceuticals
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-6 col-lg-12 col-xl-12 col-md-6 row d-flex flex-column flex-sm-column flex-lg-row flex-xl-row flex-md-column gap-md-4 gap-3 gap-lg-5 gap-xl-5 gap-xxl-5 gap-sm-3 ">
                  <div className="col-5 out-source-card3" data-aos="fade-left">
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-right"
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector15}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center text-nowrap">
                        E-commerce &<br /> Retail
                      </h4>
                    </div>
                  </div>
                  <div className="col-5 out-source-card5" data-aos="fade-left">
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-left"
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector16}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center">
                        Telecom
                      </h4>
                    </div>
                  </div>
                  <div className="col-5 out-source-card5">
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-left"
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector17}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center">
                        Real Estate
                      </h4>
                    </div>
                  </div>
                  <div className="col-5 out-source-card5" data-aos="fade-right">
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-left"
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector18}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center text-nowrap">
                        Education &<br /> E-learning
                      </h4>
                    </div>
                  </div>
                  <div className="col-5 out-source-card4" data-aos="fade-right">
                    <div className="h-50 d-flex justify-content-center align-items-center">
                      <img
                        data-aos="flip-left"
                        loading="lazy"
                        className="img-fluid bpo-vector"
                        src={Vector19}
                        alt=""
                      />
                    </div>
                    <div className="h-25 d-flex justify-content-center align-items-center">
                      <h4 className=" out-source-card-text text-white p-0 m-0 text-center">
                        Automotive
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="out-source-banner2">
          <div className="container out-source-fluid2">
            <div className="column col-12 text-center d-flex flex-column justify-content-center align-items-start align-items-sm-start align-items-lg-center align-items-xl-center align-items-md-start">
              <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                <h1 className="heading-225 col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 text-start text-sm-start text-md-start text-xl-center text-lg-center">
                  Ready to revolutionize your game?
                </h1>
                <p className="heading-226 col-12 col-sm-12 col-lg-10 col-xl-10 col-md-12 text-start text-sm-start text-md-start text-xl-center text-lg-center ">
                  Outsource your business needs to us and watch your goals shoot
                  for the stars in no time!
                </p>
              </div>

              <div className="col-8 col-sm-8 col-md-6 col-xl-2 col-lg-2 d-flex justify-content-start justify-content-sm-start justify-content-md-start  justify-content-lg-center  justify-content-xl-center align-items-center">
                <a
                  href="#contact-form"
                  type="button"
                  className=" transition  text-nowrap rounded-pill w-100 d-flex justify-content-center fill-btn outline-btn2 iconBtn"
                >
                  Consult today
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="out-source-sectionsFinal ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11 col-md-12 col-12 d-flex flex-column justify-content-center align-items-center ">
                <h2 className="heading-233 text-center col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
                  Why Outsource Your Business Needs To Us?
                </h2>
                <p className=" p-0 m-0 heading-234 text-white mb-5 text-center col-12 col-sm-12 col-lg-7 col-xl-7 col-md-10">
                  Explore the benefits of outsourcing your business needs to the
                  best Business Process Solutions company, where you get a
                  powerful combination of innovation, technology, and
                  cost-effectiveness all under one roof.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 px-4">
                <ul className="d-flex flex-column gap-5 p-0 m-0 pr-3">
                  <li className="text-white">
                    <p className="p-0 m-0 text-white">
                      <strong className="fw-bold">
                        Expertise and Skill -{" "}
                      </strong>
                      Take advantage of our specialized skills and knowledge in
                      handling various business operations with our dynamic and
                      scalable business process services, ensuring your goals
                      are met efficiently.
                    </p>
                  </li>
                  <li className="text-white">
                    <p className="p-0 m-0 text-white">
                      <strong className="fw-bold">
                        {" "}
                        Cost-Effective Solutions -{" "}
                      </strong>
                      Save big on business operations by outsourcing tasks to
                      us, eliminating the need for expensive in-house resources,
                      infrastructure, and overhead expenses.
                    </p>
                  </li>
                  <li className="text-white">
                    <p className="p-0 m-0 text-white">
                      <strong className="fw-bold">
                        Focus on Main Business Operations -{" "}
                      </strong>
                      Forget about handling every task yourself! Our business
                      process services help you focus on your core business
                      operations while we manage non-core functions efficiently.
                    </p>
                  </li>
                  <li className="text-white">
                    <p className="p-0 m-0 text-white">
                      <strong className="fw-bold">Scalability - </strong>
                      Easily scale your business operations with our outsourcing
                      services based on fluctuating demands, without the hassle
                      of hiring, training, or managing additional staff.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 mt-5 mt-sm-5 mt-lg-0 mt-xl-0 mt-md-5 ">
                <img
                  src={OutSourceCardImg}
                  className="img-fluid w-100 h-100"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <OutsourceForm />
      </main>
    </div>
  );
};

export default Index;
