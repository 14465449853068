import {
    ae_logo,
    ai_logo,
    Blue_Underline,
    Blue_Underline2,
    careerIcon1,
    careerIcon2,
    careerIcon3,
    careerIcon4,
    code_ignighter_logo,
    css_logo,
    figma_logo,
    full_stack_logo,
    HAD_bg,
    HAD_image_main,
    html5_logo,
    Laravellogo,
    Nodejs_logo,
    photo_shop_logo,
    php_logo,
    pr_logo,
    react_logo,
    react_native_logo,
    shopify_logo,
    sketch_logo,
    VectorHad1,
    VectorHad10,
    VectorHad2,
    VectorHad3,
    VectorHad4,
    VectorHad5,
    VectorHad6,
    VectorHad7,
    VectorHad8,
    VectorHad9,
    wordpress_logo,
    xd_logo,
} from "src/assets";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { motion } from 'framer-motion';
import './style.css';
const Index = () => {
    const [isPaused, setIsPaused] = useState(false);
    const [openAccordion, setOpenAccordion] = useState(null); // Track the open accordion item

    const handleAccordionToggle = (id: any) => {
        // If the clicked accordion is already open, close it, otherwise open it
        setOpenAccordion(openAccordion === id ? null : id);
    };
    return (
        <div>
            <Helmet>
                <link
                    rel="canonical"
                    href="https://ditinustechnology.com/hire-a-developer"
                />
                <title>
                    Hire Best Software Developers in India | Expert Programmers for Your Projects
                </title>
                <meta
                    name="description"
                    content="Hire best software developers in India for custom solutions, scalable apps, & innovative software. Access expert programmers for your next big project today!"
                />
                <meta
                    name="keywords"
                    content="Hire WordPress Developers, Hire PHP Programmers,Hire CodeIgniter Developers, Hire Laravel Experts,Hire Node.js Experts,Hire React.js Developers,Hire WordPress Developers,Hire Shopify Developers,Hire React Native Developers,Hire Full Stack Developers,Hire UI/UX Designers,Hire Website Designers,Hire Graphic Designers"
                />
            </Helmet>
            <main className="bg-height4 bg-kpo-bpo position-relative">
                <img src={HAD_bg} className="img-fluid cs-PageBg w-100" style={{ opacity: 0.4, zIndex: -1 }} alt="img" />
                <section className="HAD-Hero-section">
                    <div className="container ">
                        <div className="row jusity-content-center">

                            <div className="position-relative d-flex flex-column align-items-center">
                                <svg
                                    width="100%"
                                    height="100%"
                                    viewBox="0 0 1260 679"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="px-4 px-md-4 px-lg-0 px-xl-0 svg-had-animated "

                                >
                                    <motion.path
                                        d="M1257.5 647C1257.5 478.079 1198.56 315.994 1078.89 196.549C959.225 77.1036 796.924 10 627.691 10C458.459 9.99999 296.157 77.1035 176.492 196.549C56.8264 315.994 1.00003 472.12 1 641.041L1257.5 647Z"
                                        fill="url(#paint0_linear_0_11685)"
                                        fillOpacity="0.07"
                                        animate={{
                                            y: [0, 10, -10, 0], // Creating a wave effect with alternating y movements
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 0.2, // Adds a slight delay for each path for the wave effect
                                        }}
                                    />
                                    <motion.path
                                        d="M1259.5 637C1259.5 468.079 1200.56 305.994 1080.89 186.549C961.225 67.1036 798.924 1.27532e-05 629.691 0C460.459 -1.27532e-05 298.157 67.1035 178.492 186.549C58.8264 305.994 3.00003 462.12 3 631.041L1259.5 637Z"
                                        fill="url(#paint1_linear_0_11685)"
                                        fillOpacity="0.02"
                                        animate={{
                                            y: [0, 15, -15, 0], // Different amplitude for a subtle effect
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 0.4, // Adds a different delay to this path for alternating effect
                                        }}
                                    />
                                    <motion.path
                                        d="M1258.5 657C1258.5 488.079 1199.56 325.994 1079.89 206.549C960.225 87.1036 797.924 20 628.691 20C459.459 20 297.157 87.1035 177.492 206.549C57.8264 325.994 2.00003 482.12 2 651.041L1258.5 657Z"
                                        fill="url(#paint2_linear_0_11685)"
                                        fillOpacity="0.12"
                                        animate={{
                                            y: [0, 20, -20, 0], // Larger amplitude for a more dramatic wave effect
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 0.6, // Adds a slight delay for this path for an alternating effect
                                        }}
                                    />
                                    <motion.path
                                        d="M1258.5 668C1258.5 499.079 1199.56 336.994 1079.89 217.549C960.225 98.1036 797.924 31 628.691 31C459.459 31 297.157 98.1035 177.492 217.549C57.8264 336.994 2.00003 493.12 2 662.041L1258.5 668Z"
                                        fill="url(#paint3_linear_0_11685)"
                                        fillOpacity="0.18"
                                        animate={{
                                            y: [0, 10, -10, 0], // Repeating wave-like motion
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 0.8, // Slight delay for smoother alternating effect
                                        }}
                                    />
                                    <motion.path
                                        d="M1258.5 679C1258.5 510.079 1199.56 347.994 1079.89 228.549C960.225 109.104 797.924 42 628.691 42C459.459 42 297.157 109.104 177.492 228.549C57.8264 347.994 2.00003 504.12 2 673.041L1258.5 679Z"
                                        fill="url(#paint4_linear_0_11685)"
                                        animate={{
                                            y: [0, 5, -5, 0], // Small amplitude for subtle motion
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 1, // Adds a longer delay for more contrast
                                        }}
                                    />
                                    <motion.path
                                        d="M1258.62 679.001C1258.62 596.368 1242.34 514.543 1210.72 438.2C1179.09 361.857 1132.75 292.49 1074.31 234.059C1015.88 175.628 946.517 129.279 870.173 97.6561C793.83 66.0337 712.006 49.7578 629.372 49.7578C546.739 49.7578 464.915 66.0337 388.571 97.6561C312.228 129.279 242.861 175.628 184.43 234.059C125.999 292.49 79.6496 361.857 48.0272 438.2C16.4048 514.544 0.128899 596.368 0.128906 679.001L1258.62 679.001Z"
                                        fill="#07234B"
                                        animate={{
                                            y: [0, 8, -8, 0], // Another wave pattern
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 1.2, // Adds more delay for smoother wave effect
                                        }}
                                    />
                                    <defs>
                                        <linearGradient id="paint0_linear_0_11685" x1="628.865" y1="14.7005" x2="628.865" y2="757.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_0_11685" x1="630.865" y1="4.70049" x2="630.865" y2="747.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_0_11685" x1="629.865" y1="24.7005" x2="629.865" y2="767.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_0_11685" x1="629.865" y1="35.7005" x2="629.865" y2="778.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint4_linear_0_11685" x1="629.865" y1="46.7005" x2="629.865" y2="789.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className="position-absolute w-100 h-100" style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <div className="position-relative w-100 h-100">
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag" style={{ background: '#0D72B8' }}>
                                                <p className="p-0 m-0 text-white tag-title-HAD px0 px-sm-1 px-md-22">
                                                    Shopify Developers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag" style={{ background: '#0D72B8' }}>
                                                <p className="p-0 m-0 text-white tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    CodeIgniter Developers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag" style={{ background: '#041032' }}>
                                                <p className="p-0 m-0 text-white tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    WordPress Developers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag" style={{ background: '#041032' }}>
                                                <p className="p-0 m-0 text-white tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    UI/UX Designers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag" style={{ background: '#6AFF81' }}>
                                                <p className="p-0 m-0 text-black tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    React Native Developers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag" style={{ background: '#6AFF81' }}>
                                                <p className="p-0 m-0 text-black tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    React.js Developers
                                                </p>
                                            </div>
                                        </div>


                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag2" style={{ background: '#0D72B8' }}>
                                                <p className="p-0 m-0 text-white tag-title-HAD px-1 px-sm-1 px-md-2">
                                                    Laravel Experts
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag2" style={{ background: '#0D72B8' }}>
                                                <p className="p-0 m-0 text-white tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    Graphic Designers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag2" style={{ background: '#041032' }}>
                                                <p className="p-0 m-0 text-white tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    PHP Programmers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag2" style={{ background: '#6AFF81' }}>
                                                <p className="p-0 m-0 text-black tag-title-HAD px-1 px-sm-1 px-md-2">
                                                    Node.js Experts
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag2" style={{ background: '#6AFF81' }}>
                                                <p className="p-0 m-0 text-black tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    Website Designers
                                                </p>
                                            </div>
                                        </div>
                                        <div className="position-absolute bubble-tag-left">
                                            <div className="bubble-tag2" style={{ background: '#6AFF81' }}>
                                                <p className="p-0 m-0 text-black tag-title-HAD px-0 px-sm-1 px-md-2">
                                                    Full Stack Developers
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row col-12 col-sm-12 col-lg-9 col-xl-9 d-flex flex-column justify-content-center align-items-center py-md-0 py-lg-5 py-xl-5 py-xxl-5 HAD-Header-container">
                                    <p className="text-white text-center p-0 m-0 had_header_1">
                                        Why Pay More?
                                    </p>
                                    <h3 className="text-white text-center p-0 m-0 had_header_2 mt-2">
                                        Hire the Best Software <br />
                                        Developers in India at Fraction of the <span style={{ color: '#6AFF80' }}> Cost!</span>
                                    </h3>
                                    <p className="text-white text-center p-0 m-0 had_header_3 col-10 mt-2">
                                        Did You Know? Over 50,000 businesses are already saving up to 78% on development costs by hiring<span style={{ color: '#6AFF80' }}> skilled software experts in India!</span>
                                    </p>
                                    <a
                                        href="#contact-form"
                                        type="button"
                                        className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn col-7 col-xl-3 col-md-5  col-sm-6 col-lg-4 py-2 d-flex justify-content-center mt-lg-5 mt-3"
                                    >
                                        Hire Developers{" "}
                                        <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="HAD-banner" style={{ background: '#0D72B8' }}>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-8 col-lg-8 text-start d-flex flex-column justify-content-center">
                                <h2 className="heading-225 ">Don’t let high costs hold you back!</h2>
                                <p className="heading-226">
                                    Reach out to us today and discover how our talented developers in India can be a game-changer for your business!
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4 d-flex justify-content-start justify-content-sm-start justify-content-md-start  justify-content-lg-end  justify-content-xl-end align-items-center">
                                <a
                                    href="#contact-form"
                                    type="button"
                                    className="transition text-nowrap rounded-pill fill-btn6 py-2 iconBtn px-5 col-7 d-flex justify-content-center"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="HAD-sections ">
                    <div className="container">
                        <div className="pb-5" data-aos="fade-up">
                            <div className="had-main-image" style={{ background: "" }}>
                                <img loading="lazy" src={HAD_image_main} className="w-100" alt="line" />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="row justify-content-center">
                                <h3 className="text-center text-white p-0 m-0 had_header_4 ">
                                    Tap Into Our Dynamic Software Development Skill Set!
                                </h3>
                                <img src={Blue_Underline} alt="" className="blue_under_line" />
                            </div>

                            <div className="row justify-content-center gap-3 col-12 col-lg-10 mt-5">
                                <div data-aos="fade-up" className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 d-flex flex-column flex-lg-row flex-column justify-content-between p-0 m-0 gap-3 gap-lg-0">

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pe-lg-2" >
                                        <div className="col-12 py-3 px-4 rounded-4 h-100 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                            <div className="d-flex flex-row justify-content-between">
                                                <h4 className="text-white col-6 had-card-header1 ">
                                                    Hire PHP Programmers
                                                </h4>
                                                <div className="col-lg-5 col-6 d-flex align-items-start  justify-content-end" data-aos="fade-left" >
                                                    <img
                                                        src={php_logo}
                                                        alt=""
                                                        className=""
                                                        style={{ objectFit: 'contain' }}
                                                    />
                                                </div>
                                            </div>
                                            <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Create high-performance, data-driven, and dynamic websites and applications with our expert <span style={{ color: '#6AFF81' }}>PHP developers in India</span>. Our PHP programmers build secure and highly scalable solutions that grow alongside your business.</p>
                                            <a
                                                href="#contact-form"
                                                type="button"
                                                className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                            >
                                                Book Free Consultation

                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ps-lg-2" >
                                        <div className="col-12 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                            <div className="d-flex flex-row justify-content-between">
                                                <h4 className="text-white col-6 col-sm-7 col-lg-6 had-card-header1 ">
                                                    Hire CodeIgniter Developers
                                                </h4>

                                                <div className="col-lg-6 col-sm-5 col-6 d-flex justify-content-end align-items-start" data-aos="fade-left" >
                                                    <img
                                                        src={code_ignighter_logo}
                                                        alt=""
                                                        className="img-fluid"
                                                        style={{ objectFit: 'contain' }}

                                                    />
                                                </div>
                                            </div>
                                            <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Did you know? Websites built with CodeIgniter can load up to 40% faster! Explore the potential of our affordable <span style={{ color: '#6AFF81' }}>CodeIgniter web development in India!</span> Our experts create futuristic, seamless websites in no time. With easy maintenance, improved user experience, and smooth integration, you’ll benefit from a high-tech solution backed by a supportive community.</p>
                                            <a
                                                href="#contact-form"
                                                type="button"
                                                className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                            >
                                                Book Free Consultation

                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-up" className="col-12 col-lg-11 p-0 m-0" >
                                    <div className="col-12 py-lg-4 px-lg-5 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                        <div className="d-flex flex-row justify-content-between">
                                            <h4 className="text-white col-4 col-lg-6 had-card-header1 ">
                                                Hire Laravel Experts
                                            </h4>
                                            <div className="col-lg-5 col-8 d-flex justify-content-end align-items-center" data-aos="fade-left" >
                                                <img
                                                    src={Laravellogo}
                                                    alt=""
                                                    className="img-fluid"
                                                    style={{ objectFit: 'contain' }}

                                                />
                                            </div>
                                        </div>
                                        <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Leverage the power of Laravel’s syntax and features to build solutions that enhance user experience, accelerate time to market, and drive tangible outcomes. Whether you need custom web application development, e-commerce solutions, API development and integration, maintenance and support, or migration from other platforms, our <span style={{ color: '#6AFF81' }}>Laravel developers in India</span> have you covered under one expert roof.</p>
                                        <a
                                            href="#contact-form"
                                            type="button"
                                            className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                        >
                                            Book Free Consultation

                                        </a>
                                    </div>
                                </div>
                                {/* //2 */}
                                <div data-aos="fade-up" className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 d-flex flex-column flex-lg-row flex-column justify-content-between p-0 m-0 gap-3 gap-lg-0">

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pe-lg-2 ">
                                        <div className="col-12 py-3 px-4 rounded-4 h-100 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                            <div className="d-flex flex-row justify-content-between">
                                                <h4 className="text-white col-6 had-card-header1 ">
                                                    Hire Node.js Experts
                                                </h4>
                                                <div className="col-lg-5 col-5 d-flex justify-content-end align-items-center" data-aos="fade-left" >
                                                    <img
                                                        src={Nodejs_logo}
                                                        alt=""
                                                        className="img-fluid"
                                                        style={{ objectFit: 'contain' }}

                                                    />
                                                </div>

                                            </div>
                                            <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">
                                                Want to create fast, real-time applications? Our Node.js experts are ready to help you tap into the full potential of this powerful JavaScript runtime. With its non-blocking, event-driven architecture, Node.js is perfect for building applications that require high performance and usability.
                                            </p>
                                            <a
                                                href="#contact-form"
                                                type="button"
                                                className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                            >
                                                Book Free Consultation

                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ps-lg-2" >
                                        <div className="col-12 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                            <div className="d-flex flex-row justify-content-between">
                                                <h4 className="text-white col-7 had-card-header1 ">
                                                    Hire React.js Developers
                                                </h4>

                                                <div className="col-lg-5 col-5 d-flex align-items-start  justify-content-end" data-aos="fade-left" >
                                                    <img
                                                        src={react_logo}
                                                        alt=""
                                                        className=""
                                                        style={{ objectFit: 'contain' }}
                                                    />
                                                </div>
                                            </div>
                                            <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">React.js is a popular choice for building responsive, dynamic, and high-performance web applications that are easy to deploy. Its capabilities ensure rapid development and exceptional user experience, making it the best choice for futuristic web projects. Join the millions club and transform your growth today!</p>
                                            <a
                                                href="#contact-form"
                                                type="button"
                                                className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                            >
                                                Book Free Consultation

                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-up" className="col-12 col-lg-11 p-0 m-0" >
                                    <div className="col-12 py-lg-4 px-lg-5 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                        <div className="d-flex flex-row justify-content-between">
                                            <h4 className="text-white col-8 col-lg-7 had-card-header1 ">
                                                Hire WordPress Developers
                                            </h4>
                                            <div className="col-lg-5 col-4 d-flex align-items-center  justify-content-end" data-aos="fade-left" >
                                                <img
                                                    src={wordpress_logo}
                                                    alt=""
                                                    className=""
                                                    style={{ objectFit: 'contain' }}
                                                />
                                            </div>

                                        </div>
                                        <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Leverage the power of this dynamic and versatile content management system! Our <span style={{ color: '#6AFF81' }}>WordPress developers in India</span> create customized solutions tailored to your unique business needs and objectives. Whether you need a unique e-commerce store, or a corporate website, we have the expertise to deliver exceptional results.</p>
                                        <a
                                            href="#contact-form"
                                            type="button"
                                            className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                        >
                                            Book Free Consultation

                                        </a>
                                    </div>
                                </div>
                                {/* //3 */}
                                <div data-aos="fade-up" className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 d-flex flex-column flex-lg-row flex-column justify-content-between p-0 m-0 gap-3 gap-lg-0">

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pe-lg-2 ">
                                        <div className="col-12 py-3 px-4 rounded-4 h-100 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                            <div className="d-flex flex-row justify-content-between">
                                                <h4 className="text-white col-7 col-lg-7 had-card-header1 ">
                                                    Hire Shopify Developers
                                                </h4>
                                                <div className="col-lg-5 col-5 d-flex align-items-start mt-3 mt-sm-0 justify-content-end" data-aos="fade-left" >
                                                    <img
                                                        src={shopify_logo}
                                                        alt=""
                                                        className="img-fluid"
                                                        style={{ objectFit: 'contain' }}
                                                    />
                                                </div>
                                            </div>
                                            <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Are you looking to create attractive storefronts that showcase your business to your target audience or e-commerce solutions that redefine the shopping experience for your clients? Hire our team of expert <span style={{ color: '#6AFF81' }}>Shopify developers in India</span> today and elevate your journey like never before!</p>
                                            <a
                                                href="#contact-form"
                                                type="button"
                                                className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                            >
                                                Book Free Consultation

                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ps-lg-2" >
                                        <div className="col-12 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                            <div className="d-flex flex-row justify-content-between">
                                                <h4 className="text-white col-6 col-sm-7 had-card-header1 ">
                                                    Hire React Native Developers
                                                </h4>

                                                <div className="col-lg-5 col-6 col-sm-5 d-flex align-items-start  justify-content-end" data-aos="fade-left" >
                                                    <img
                                                        src={react_native_logo}
                                                        alt=""
                                                        className=""
                                                        style={{ objectFit: 'contain' }}
                                                    />
                                                </div>
                                            </div>
                                            <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Are you looking to build applications that work like butter on both iOS and Android? Our <span style={{ color: '#6AFF81' }}>React Native developers in India</span> create powerful applications that deliver a native-like experience while optimizing development time and costs. <span style={{ color: '#6AFF81' }}>Build a Strong Online Presence with React Native, Just Like Facebook, Airbnb, and Instagram did!</span> Hire our expert React Native developers and bring your app ideas to life!</p>
                                            <a
                                                href="#contact-form"
                                                type="button"
                                                className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                            >
                                                Book Free Consultation

                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-up" className="col-12 col-lg-11 p-0 m-0" >
                                    <div className="col-12 py-lg-4 px-lg-5 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                        <div className="d-flex flex-row justify-content-between">
                                            <h4 className="text-white col-8 col-lg-7 had-card-header1 ">
                                                Hire Full Stack Developers
                                            </h4>

                                            <div className="col-lg-5 col-4 d-flex align-items-start  justify-content-end" data-aos="fade-left">
                                                <img
                                                    src={full_stack_logo}
                                                    alt=""
                                                    className=""
                                                    style={{ objectFit: 'contain' }}
                                                />
                                            </div>
                                        </div>
                                        <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Are you looking for multi-skilled developers who are pros at both the front-end and back-end development services of your applications? Our <span style={{ color: '#6AFF81' }}>expert full-stack development team</span> is equipped with the right skills and expertise to create
                                            advanced web solutions that take your business objectives fast forward. Bring Your Dream Project to Life with Us! Our Full Stack Development Team is dedicated to helping you achieve your goals in half the time.</p>
                                        <a
                                            href="#contact-form"
                                            type="button"
                                            className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                        >
                                            Book Free Consultation

                                        </a>
                                    </div>
                                </div>
                                {/* //4 */}
                                <div data-aos="fade-up" className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 d-flex flex-column flex-lg-row flex-column justify-content-between p-0 m-0 gap-3 gap-lg-0">

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pe-lg-2 ">
                                        <div className="col-12 py-3 px-4 rounded-4 h-100 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                            <div className="d-flex flex-row justify-content-between">
                                                <h4 className="text-white col-6 col-lg-5 had-card-header1">
                                                    Hire UI/UX Designers
                                                </h4>
                                                <div className="col-6 col-lg-7 d-flex flex-row gap-lg-2 gap-1 flex-wrap justify-content-end " data-aos="fade-left">

                                                    <img src={figma_logo} alt="" className="had-logo" style={{ objectFit: 'contain' }} />
                                                    <img src={xd_logo} alt="" className="had-logo " style={{ objectFit: 'contain' }} />
                                                    <img src={sketch_logo} alt="" className="had-logo " style={{ objectFit: 'contain' }} />
                                                </div>
                                            </div>
                                            <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Are you sure poor UI/UX design isn't driving your customers away? A great design makes a huge impact on how you market your business! Don’t let confusing layouts and frustrating user experiences hold you back. Hire our expert UI/UX designers to craft intuitive layouts, enable faster interactions, and deliver seamless functionality. Enhance your customer experience and watch your sales grow! </p>
                                            <a
                                                href="#contact-form"
                                                type="button"
                                                className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                            >
                                                Book Free Consultation

                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ps-lg-2" >
                                        <div className="col-12 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                            <div className="d-flex flex-row justify-content-between">
                                                <h4 className="text-white col-7 col-lg-6 had-card-header1 ">
                                                    Hire Website Designers
                                                </h4>
                                                <div className="col-5 col-lg-6 col-xl-5 d-flex flex-row gap-2 gap-lg-0 flex-wrap justify-content-end" data-aos="fade-left">

                                                    <img src={html5_logo} alt="" className="had-logo" style={{ objectFit: 'contain' }} />
                                                    <img src={css_logo} alt="" className="had-logo ps-2" style={{ objectFit: 'contain' }} />
                                                </div>
                                            </div>
                                            <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Your website is the first impression of your business to your target audience, and poor design can do more harm than good! A badly designed site drives potential customers away, often never to return. Don’t let an outdated or confusing website drive your customers into your competitors' arms. Hire our expert team of <span style={{ color: '#6AFF81' }}>web designers in India</span> to create websites with fast load times, highly responsive, and smooth navigation—ensuring your customers stay, engage, and come back for more!</p>
                                            <a
                                                href="#contact-form"
                                                type="button"
                                                className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                            >
                                                Book Free Consultation

                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div data-aos="fade-up" className="col-12 col-lg-11 p-0 m-0" >
                                    <div className="col-12 py-lg-4 px-lg-5 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100" style={{ backgroundColor: '#0D72B852' }}>

                                        <div className="d-flex flex-row justify-content-between">
                                            <h4 className="text-white col-7 col-md-6 had-card-header1 ">
                                                Hire Graphic Designers
                                            </h4>
                                            <div className="col-5 col-md-6 col-lg-6 col-xl-5 d-flex flex-row gap-1 gap-lg-2 flex-wrap justify-content-end" data-aos="fade-left">
                                                <img src={photo_shop_logo} alt="" className="had-logo2" />
                                                <img src={ai_logo} alt="" className="had-logo2" />
                                                <img src={ae_logo} alt="" className="had-logo2" />
                                                <img src={pr_logo} alt="" className="had-logo2" />
                                            </div>
                                        </div>
                                        <p className="p-0 m-0 col-12 text-white py-4 had-card-desc1">Inconsistent branding and poor quality visuals are a red flag to the credibility of your website and can drive growth far down the highway! With our expert team of <span style={{ color: '#6AFF81' }}>graphic designers in India,</span> we help you communicate your vision precisely. From stunning logos to engaging social media graphics, we ensure that you stand out among your competitors and steal the limelight.</p>
                                        <a
                                            href="#contact-form"
                                            type="button"
                                            className=" transition text-nowrap rounded-pill fill-btn5 iconBtn px-5 d-flex justify-content-center"
                                        >
                                            Book Free Consultation

                                        </a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

                <section className="HAD-sections ">
                    <div className="container ">
                        <div className="row justify-content-center">

                            <div className="row justify-content-center">
                                <h3 className="text-center text-white p-0 m-0 had_header_4 ">
                                    Curious About Why You Should Choose Us?
                                </h3>
                                <img src={Blue_Underline} alt="" className="blue_under_line-2" />
                            </div>

                            <div className="row justify-content-center gap-4 gap-lg-3 col-12 mt-5">
                                <div className="col-12 d-flex flex-column flex-lg-row flex-column justify-content-between p-0 m-0 gap-4 gap-lg-0">

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pe-lg-2 ">
                                        <div className="col-12 py-3 px-4 rounded-4 h-100 d-flex flex-column justify-content-between h-100 position-relative" style={{ backgroundColor: '#0D72B852' }}>
                                            <div className="col-12 col-lg-7 d-flex flex-column ">
                                                <h4 className="p-0 m-0 text-white text-start had_v_card_h">
                                                    Slash Costs, Not Quality
                                                </h4>
                                                <p className="p-0 m-0 text-white text-start mt-3 had_v_card_d">
                                                    <span style={{ color: '#6AFF81' }}>Great innovation doesn’t have to cost a fortune!</span><br />
                                                    When you outsource to us, you can say goodbye to hefty overhead costs and hello to budget-friendly solutions that don’t compromise on quality. Trust us with your needs and transform your brand without draining your resources!
                                                </p>
                                                <div className=" had_vector_container " data-aos="fade-left">
                                                    <img src={VectorHad1} className="img-fluid had_vector aos-init" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ps-lg-2" >
                                        <div className="col-12 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100 position-relative" style={{ backgroundColor: '#0D72B852' }}>
                                            <div className="col-12 col-lg-7 d-flex flex-column ">
                                                <h4 className="p-0 m-0 text-white text-start had_v_card_h">
                                                    Fresh Ideas
                                                </h4>
                                                <p className="p-0 m-0 text-white text-start mt-3 had_v_card_d">
                                                    We don’t follow the herd; instead, we dedicate our time to building solutions with an out-of-the-box perspective for your business. We don’t do what everyone else is doing; with us, you’ll gain access to crisp and fresh ideas that truly transform.
                                                </p>
                                            </div>
                                            <div className=" had_vector_container" data-aos="fade-left">
                                                <img src={VectorHad2} className="img-fluid had_vector" alt="" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12  p-0 m-0" >
                                    <div className="col-12 py-lg-5 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100 position-relative" style={{ backgroundColor: '#0D72B852' }}>
                                        <div className="col-12 col-lg-8 d-flex flex-column ">
                                            <h4 className="p-0 m-0 text-white text-start had_v_card_h">
                                                Expertise at Your Fingertips
                                            </h4>
                                            <p className="p-0 m-0 text-white text-start mt-3 had_v_card_d">
                                                We are supported by a talented team of top coders, developers, and <span style={{ color: '#6AFF81' }}>software experts in India</span>, each bringing years of experience in managing projects of all sizes. Before joining our team, each candidate undergoes a series of rigorous interviews to ensure we hire only the best talent in the industry. Our expertise spans a wide range of industries, including IT, travel and tourism, logistics, agriculture, and more. This diverse knowledge enables us to deliver tailored solutions that meet the unique challenges of each sector, ensuring that your project is in capable hands.
                                            </p>
                                        </div>
                                        <div className=" had_vector_container" data-aos="fade-left">
                                            <img src={VectorHad3} className="img-fluid had_vector" alt="" />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12 d-flex flex-column flex-lg-row flex-column justify-content-between p-0 m-0 gap-4 gap-lg-0">

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pe-lg-2 ">
                                        <div className="col-12 py-3 px-4 rounded-4 h-100 d-flex flex-column justify-content-between h-100 position-relative" style={{ backgroundColor: '#0D72B852' }}>
                                            <div className="col-12 col-lg-7 d-flex flex-column ">
                                                <h4 className="p-0 m-0 text-white text-start had_v_card_h">
                                                    Time Zone Benefit
                                                </h4>
                                                <p className="p-0 m-0 text-white text-start mt-3 had_v_card_d">
                                                    Time zones are no bar in our outsourcing process. We ensure that your projects are in constant motion, no matter where you are. Our skilled engineers and <span style={{ color: '#6AFF81' }}>developers in India</span> work continuously, allowing you to focus on your core competencies while we prioritize meeting your deadlines ahead of schedule.
                                                </p>
                                            </div>
                                            <div className=" had_vector_container" data-aos="fade-left">
                                                <img src={VectorHad4} className="img-fluid had_vector" alt="" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ps-lg-2" >
                                        <div className="col-12 py-3 px-4 rounded-4 d-flex flex-column justify-content-between h-100 position-relative" style={{ backgroundColor: '#0D72B852' }}>
                                            <div className="col-12 col-lg-7 d-flex flex-column ">
                                                <h4 className="p-0 m-0 text-white text-start had_v_card_h">
                                                    Advanced Tools and Technology
                                                </h4>
                                                <p className="p-0 m-0 text-white text-start mt-3 had_v_card_d">
                                                    We don’t adhere to outdated practices or rely on outdated technology. In today’s fast-paced, competitive landscape, our <span style={{ color: '#6AFF81' }}>outsourcing agency in India</span> is committed to using only the most advanced tools and technologies to accelerate your growth prospects.
                                                </p>
                                            </div>
                                            <div className="had_vector_container" data-aos="fade-left">
                                                <img src={VectorHad5} className="img-fluid had_vector" alt="" />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="HAD-sections ">
                    <div className="container ">
                        <div className="row justify-content-center">

                            <div className="row justify-content-center">
                                <h3 className="text-center text-white p-0 m-0 had_header_4 ">
                                    Our Process of Bringing Your Ideas to Life
                                </h3>
                                <img src={Blue_Underline} alt="" className="blue_under_line-2" />
                            </div>
                            <div className="row justify-content-center justify-content-xxl-between  d-flex flex-wrap mt-lg-4 pt-lg-1 g-3 gap-3 gap-md-0 g-xxl-4 g-xl-4 g-lg-4 g-md-4">
                                <div
                                    className="step_box_had"
                                    data-aos="fade-up"
                                >
                                    <div className="pt-4 px-3 h-100 rounded-5 d-flex flex-column" style={{
                                        border: ' 0.74px solid #0D72B8',
                                        boxShadow: '0px 0px 5.9px 0px #0D72B8',
                                        backdropFilter: 'blur(5.159944534301758px)',
                                        background: '#0D72B81F',
                                        transition: 'box-shadow 0.3s ease',

                                    }}
                                        onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 10px 2px #0D72B8'}
                                        onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 5.9px 0px #0D72B8'}
                                    >
                                        <div className="img-icon aos-init aos-animate text-center" data-aos="fade-left">
                                            <img loading="lazy" src={VectorHad9} alt="icon" />
                                        </div>
                                        <h3 className='p-0 m-0 text-center text-white mt-3 had_step_M'>Step 1</h3>
                                        <h5 className="p-0 m-0 text-white text-center had_step_card_H  mt-3 px-lg-4 px-md-3">Understanding You</h5>
                                        <p className="text-white text-center had_step_card_d mt-3 pb-3 pb-lg-0">
                                            Before we begin working with you, we take the time to fully understand your unique goals and objectives. This allows us to tailor every strategy to align with your objectives, rather than taking an unfocused approach.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="step_box_had"
                                    data-aos="fade-up"
                                >
                                    <div className="pt-4 px-3 h-100 rounded-5 d-flex flex-column" style={{
                                        border: ' 0.74px solid #0D72B8',
                                        boxShadow: '0px 0px 5.9px 0px #0D72B8',
                                        backdropFilter: 'blur(5.159944534301758px)',
                                        background: '#0D72B81F',
                                        transition: 'box-shadow 0.3s ease',

                                    }}
                                        onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 10px 2px #0D72B8'}
                                        onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 5.9px 0px #0D72B8'}
                                    >
                                        <div className="img-icon text-center" data-aos="fade-left">
                                            <img loading="lazy" src={VectorHad10} alt="icon" />
                                        </div>
                                        <h3 className='p-0 m-0 text-center text-white mt-3 had_step_M'>Step 2</h3>
                                        <h5 className="p-0 m-0 text-white text-center had_step_card_H mt-3 px-md-3">
                                            Designed Just for You!
                                        </h5>
                                        <p className="text-white text-center had_step_card_d mt-3 pb-3 pb-lg-0">
                                            We don't offer outdated, one-size-fits-all solutions. Instead, we deliver strategies that are uniquely focused on you. Our outsourcing services in India are designed to address every aspect of your business, ensuring you receive fresh, innovative ideas tailored to your needs.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="step_box_had"
                                    data-aos="fade-up"
                                >
                                    <div className="pt-4 px-3 h-100 rounded-5 d-flex flex-column" style={{
                                        border: ' 0.74px solid #0D72B8',
                                        boxShadow: '0px 0px 5.9px 0px #0D72B8',
                                        backdropFilter: 'blur(5.159944534301758px)',
                                        background: '#0D72B81F',
                                        transition: 'box-shadow 0.3s ease',
                                    }}
                                        onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 10px 2px #0D72B8'}
                                        onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 5.9px 0px #0D72B8'}
                                    >
                                        <div className="img-icon text-center" data-aos="fade-left">
                                            <img loading="lazy" src={VectorHad8} alt="icon" />
                                        </div>
                                        <h3 className='p-0 m-0 text-center text-white mt-3 had_step_M'>Step 3</h3>
                                        <h5 className="p-0 m-0 text-white text-center had_step_card_H mt-3 px-md-3">Smooth Implementation</h5>
                                        <p className="text-white text-center had_step_card_d mt-3 pb-3 pb-lg-0">
                                            We believe in complete transparency of information with our clients and that is why our skilled team of coding experts and developers in India handle the entire process with precision, from development to delivery, while keeping you informed at every stage.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="step_box_had"
                                    data-aos="fade-up"
                                >
                                    <div className="pt-4 px-3 h-100 rounded-5 d-flex flex-column" style={{
                                        border: ' 0.74px solid #0D72B8',
                                        boxShadow: '0px 0px 5.9px 0px #0D72B8',
                                        backdropFilter: 'blur(5.159944534301758px)',
                                        background: '#0D72B81F',
                                        transition: 'box-shadow 0.3s ease',

                                    }}
                                        onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 10px 2px #0D72B8'}
                                        onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 5.9px 0px #0D72B8'}
                                    >
                                        <div className="img-icon text-center" data-aos="fade-left">
                                            <img loading="lazy" src={VectorHad6} alt="icon" />
                                        </div>
                                        <h3 className='p-0 m-0 text-center text-white mt-3 had_step_M'>Step 4</h3>
                                        <h5 className="p-0 m-0 text-white text-center had_step_card_H mt-3 px-md-3 px-lg-0 "><span className="text-nowrap" style={{ textWrap: 'nowrap' }}>Quality Assurance</span> and Testing</h5>
                                        <p className="text-white text-center had_step_card_d mt-3 pb-3 pb-lg-0">
                                            Each project handled by our outsourcing team in India undergoes rigorous testing and quality checks to ensure flawless performance. We guarantee that everything runs smoothly, without glitches or errors, and that the final product meets the highest standards of quality.
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="step_box_had"
                                    data-aos="fade-up"
                                >
                                    <div className="pt-4 px-3 h-100 rounded-5 d-flex flex-column" style={{
                                        border: ' 0.74px solid #0D72B8',
                                        boxShadow: '0px 0px 5.9px 0px #0D72B8',
                                        backdropFilter: 'blur(5.159944534301758px)',
                                        background: '#0D72B81F',
                                        transition: 'box-shadow 0.3s ease',

                                    }}
                                        onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0px 0px 10px 2px #0D72B8'}
                                        onMouseLeave={(e) => e.currentTarget.style.boxShadow = '0px 0px 5.9px 0px #0D72B8'}
                                    >
                                        <div className="img-icon text-center" data-aos="fade-left">
                                            <img loading="lazy" src={VectorHad7} alt="icon" />
                                        </div>
                                        <h3 className='p-0 m-0 text-center text-white mt-3 had_step_M'>Step 5</h3>
                                        <h5 className="p-0 m-0 text-white text-center had_step_card_H mt-3 px-lg-5 px-md-3">On-Time Delivery</h5>
                                        <p className="text-white text-center had_step_card_d mt-3 pb-3 pb-lg-0">
                                            We stick to timelines and ensure the finished project is delivered on time, ready to launch without delays. Our commitment to delivering projects on time is what makes us stand out aming the best outsourcing agencies in India.
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div >
                    </div >
                </section >

                <section className="HAD-sections mb-5">
                    <div className="container ">
                        <div className="row justify-content-center">

                            <div className="row justify-content-center">
                                <h3 className="text-center text-white p-0 m-0 had_header_4 ">
                                    FAQ’s
                                </h3>
                                <img src={Blue_Underline2} style={{ objectFit: 'fill' }} alt="" className="blue_under_line-3" />
                            </div>
                            <div className=" mt40">
                                <div
                                    className="accordion accordion-flush"
                                    id="accordionFlushExample"
                                >
                                    <div className="accordion-item rounded-3 border-0 shadow mb-3">
                                        <h2 className="accordion-header">
                                            <button
                                                className={`accordion-button ${openAccordion === 'one' ? 'collapsed' : ''} had_acc_h rounded-3`}
                                                type="button"
                                                onClick={() => handleAccordionToggle('one')}
                                                aria-expanded={openAccordion === 'one' ? 'true' : 'false'}
                                                aria-controls="flush-collapseOne"
                                            >
                                                Why should I hire a developer with your agency?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseOne"
                                            className={`accordion-collapse collapse ${openAccordion === 'one' ? 'show' : ''}`}
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p className='had_acc_p'>
                                                    Our IT services in India offer tailored solutions that address every aspect of your business needs. Backed by a team of highly skilled professionals with years of experience, we have successfully managed a wide range of projects across diverse industries. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item rounded-3 border-0 shadow mb-3">
                                        <h2 className="accordion-header">
                                            <button
                                                className={`accordion-button ${openAccordion === 'two' ? 'collapsed' : ''} had_acc_h rounded-3`}
                                                type="button"
                                                onClick={() => handleAccordionToggle('two')}
                                                aria-expanded={openAccordion === 'two' ? 'true' : 'false'}
                                                aria-controls="flush-collapseTwo"
                                            >
                                                How do you ensure the timely delivery of projects?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseTwo"
                                            className={`accordion-collapse collapse ${openAccordion === 'two' ? 'show' : ''}`}
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p className='had_acc_p'>
                                                    Meeting deadlines is a crucial part of our collaboration with you. Before starting any project, we maintain transparent communication, providing a clear timeline and delivery schedule to ensure expectations are aligned.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item rounded-3 border-0 mb-3 shadow">
                                        <h2 className="accordion-header">
                                            <button
                                                className={`accordion-button ${openAccordion === 'three' ? 'collapsed' : ''} had_acc_h rounded-3`}
                                                type="button"
                                                onClick={() => handleAccordionToggle('three')}
                                                aria-expanded={openAccordion === 'three' ? 'true' : 'false'}
                                                aria-controls="flush-collapseThree"
                                            >
                                                Will hiring a developer at your agency help me save costs?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseThree"
                                            className={`accordion-collapse collapse ${openAccordion === 'three' ? 'show' : ''}`}
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p className='had_acc_p'>
                                                    Yes, outsourcing your business needs to us goes a long way in helping you save costs. Our IT agency in India provides excellent services at competitive rates, allowing you to optimize your budget accordingly.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item rounded-3 border-0 mb-3 shadow">
                                        <h2 className="accordion-header">
                                            <button
                                                className={`accordion-button ${openAccordion === 'four' ? 'collapsed' : ''} had_acc_h rounded-3`}
                                                type="button"
                                                onClick={() => handleAccordionToggle('four')}
                                                aria-expanded={openAccordion === 'four' ? 'true' : 'false'}
                                                aria-controls="flush-collapseFour"
                                            >
                                                Can you handle large-scale projects?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseFour"
                                            className={`accordion-collapse collapse ${openAccordion === 'four' ? 'show' : ''}`}
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p className='had_acc_p'>
                                                    Absolutely! Our team of coders and developers in India has years of experience handling projects of all sizes, regardless of complexity. We are committed to maintaining a consistent level of quality and excellence throughout every project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div
                                    className="accordion accordion-flush"
                                    id="accordionFlushExample"
                                >
                                    <div className="accordion-item rounded-3 border-0 shadow mb-2">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed had_acc_h rounded-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseTwo"
                                            >
                                                How do you ensure the timely delivery of projects?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseTwo"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p className='had_acc_p'>
                                                    Meeting deadlines is a crucial part of our collaboration with you. Before starting any project, we maintain transparent communication, providing a clear timeline and delivery schedule to ensure expectations are aligned.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item rounded-3 border-0 mb-2 shadow">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed had_acc_h rounded-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseThree"
                                            >
                                                Will hiring a developer at your agency help me save costs?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseThree"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p className='had_acc_p'>
                                                    Yes, outsourcing your business needs to us goes a long way in helping you save costs. Our IT agency in India provides excellent services at competitive rates, allowing you to optimize your budget accordingly.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item rounded-3 border-0 mb-2 shadow">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed had_acc_h rounded-3"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseFour"
                                            >
                                                Can you handle large-scale projects?
                                            </button>
                                        </h2>
                                        <div
                                            id="flush-collapseFour"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                <p className='had_acc_p'>
                                                    Absolutely! Our team of coders and developers in India has years of experience handling projects of all sizes, regardless of complexity. We are committed to maintaining a consistent level of quality and excellence throughout every project.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </section>
            </main >
        </div >
    );
};

export default Index;
