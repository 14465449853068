import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row, Col, Nav } from "react-bootstrap";
const Index = () => {
  const [activeSection, setActiveSection] = useState<string>(
    "acceptance-of-the-terms"
  );
  const observerRef = useRef<IntersectionObserver | null>(null);

  interface Section {
    id: string;
    title: string;
  }

  const sections: Section[] = [
    {
      id: "acceptance-of-the-terms",
      title: "Acceptance of the Terms",
    },
    {
      id: "service-overview",
      title: "Service Overview",
    },
    {
      id: "information-submission-get-a-quote",
      title: "Information Submission & Get a Quote",
    },
    { id: "payment-terms", title: "Payment Terms" },
    {
      id: "no-refund-policy",
      title: "No Refund Policy",
    },
    {
      id: "representations-by-the-user",
      title: "Representations by the User",
    },
    {
      id: "reviews-and-submissions-by-users",
      title: "Reviews and Submissions by Users",
    },
    {
      id: "changes-to-the-terms",
      title: "Changes to the Terms",
    },
    { id: "ownership-of-ip-rights", title: "Ownership of IP Rights" },
    {
      id: "prohibited-activities",
      title: "Prohibited Activities",
    },
    { id: "term-and-termination", title: "Term and Termination" },
    {
      id: "grievances-redressals-mechanism",
      title: "Grievances Redressals Mechanism",
    },
    { id: "our-role-disclaimer", title: "Our Role & Disclaimer" },
    {
      id: "other-applicable-policies",
      title: "Other Applicable Policies",
    },
    { id: "dispute-resolution", title: "Dispute Resolution" },
    { id: "limitation-of-liability", title: "Limitation of Liability" },
    {
      id: "indemnification",
      title: "Indemnification",
    },
    { id: "governing-law", title: "Governing Law" },
    { id: "general-terms", title: "General Terms" },
    { id: "contact-us", title: "Contact us" },
  ];
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 938);
    };

    // Call handleResize initially
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    // Create intersection observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      {
        rootMargin: "-20% 0px -80% 0px",
      }
    );

    sections.forEach(({ id }) => {
      const element = document.getElementById(id);
      if (element) {
        observerRef.current?.observe(element);
      }
    });

    const updateActiveSection = () => {
      const visibleSection = sections.find(({ id }) => {
        const element = document.getElementById(id);
        if (!element) return false;

        const rect = element.getBoundingClientRect();
        return (
          rect.top >= -window.innerHeight * 0.2 &&
          rect.top < window.innerHeight * 0.8
        );
      });


      if (visibleSection && visibleSection.id) {
        setActiveSection(visibleSection.id);
      }
    };
    updateActiveSection();

    window.addEventListener("scroll", updateActiveSection);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      window.removeEventListener("scroll", updateActiveSection);
    };
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ditinustechnology.com/terms-condition"
        />
        <title>Terms and Conditions | Ditinus Technology</title>
      </Helmet>
      <div>
        <div>
          <main className="bg-height4 bg-kpo-bpo blog-list1 career-page">
            <section className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 text-center top-banner">
                  <h1 className="heading-01">Terms of Use & Refund Policy</h1>
                </div>
              </div>
            </section>
          </main>
        </div>

        <div
          className="text-center text-white "
          style={{ marginBottom: "30px" }}
        >
          <h6>Last updated on Sunday, 01 December 2024</h6>
          <h6>Place: Mohali, Punjab</h6>
        </div>

        <section className="form-list-career text-WH terms15">
          <div className="container">

            <div
              className="row container"
            // style={{ overflowX: "auto", maxHeight: "2000px" }}
            >
              <div className="col  ">
                <div className="col-12 text-start">
                  <p style={{ fontSize: "17px" }}>
                    Welcome to <Link to="/"> https://ditinustechnology.com</Link>{" "}
                    , this website (collectively known as “Platform”) is owned and
                    operated by Ditinus Technology Private Limited, having its
                    registered office at Plot E-302, PH-8, Vista Tower, Mohali,
                    Punjab – 160062. By using our platform and services, you are
                    agreeing to be bound by our terms and conditions or this
                    Service Level Agreement.
                  </p>
                  <p style={{ fontSize: "17px" }}>
                    This electronic record is generated by a computer system and
                    does not require any physical or digital signatures. Please
                    ensure that you read and understand all the provisions of this
                    document before you start using our platform, as you shall be
                    bound by all the terms and conditions herein mentioned in this
                    electronic contract. If you do not agree or accept any of the
                    Terms and Conditions contained herein, then please do not use
                    the Ditinus website (hereinafter referred to as “Ditinus”,
                    “platform”, “We”, “Us”, “Site”, or “Website”) or avail any of
                    the services being provided therein.
                  </p>
                  <p style={{ fontSize: "17px" }}>
                    By visiting our platform and accessing the information and
                    services being provided herein, you understand and agree to
                    accept and adhere to the following terms and conditions as
                    stated in this policy (hereafter referred to as ‘Service Level
                    Agreement’ or ‘User Agreement’, or ‘Terms of Use’), along with
                    the terms and conditions as stated in our Privacy Policy
                    (please refer to the Privacy Policy link available on our
                    Platform for more information).
                  </p>
                  <p style={{ fontSize: "17px" }}>
                    We reserve the right to change this Service Level Agreement
                    from time to time without notice. You acknowledge and agree
                    that it is your responsibility to review this Service Level
                    Agreement periodically to familiarize yourself with any
                    modifications. Your continued use of this site after such
                    modifications will constitute acknowledgment and agreement of
                    the modified terms and conditions.
                  </p>
                </div>
                <p id="acceptance-of-the-terms" style={{ fontSize: "17px" }}>
                  If this document is not in a language that you understand, you
                  shall contact us at the email{" "}
                  <Link to="mailto:info@ditinustechnology.com">
                    info@ditinustechnology.com
                  </Link>
                  ., failure to do so within 24 (Twenty-Four) hours from the
                  time of receipt of this document and your continued use of our
                  platform after that shall be considered as your understanding
                  and acceptance of the terms and conditions of this document.
                </p>
                <div className="row px-2">
                  <ol className="">
                    <li

                      className=" fw-bold fs-large mb-3  responsive-font-size "
                    >
                      Acceptance of the Terms
                    </li>
                    <div style={{ paddingLeft: "6px", marginBottom: "20px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 1.1. </p>
                        <p className="p-0 m-0">
                          {" "}
                          These Terms & Conditions constitute a legally binding
                          agreement made between you as an Individual User
                          whether personally or on behalf of an entity (“User”,)
                          and Ditinus Technology Private Limited, doing business
                          as Ditinus, concerning your access to and use of the
                          <Link to="/"> https://ditinustechnology.com</Link> as
                          well as any other media form, media channel, linked,
                          or otherwise connected thereto as a digital platform
                          to access, manage and control information in relation
                          to the Ditinus. You agree that by accessing the Site
                          or making a purchase of subscription plan through
                          Site, you have read, understood, and agree to be bound
                          by all of these Terms & Conditions.
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 1.2. </p>
                        <p className="p-0 m-0">
                          {" "}
                          If you do not agree with all of these terms of use,
                          then you are expressly prohibited from using Ditinus
                          or its digital platform or services provided herein,
                          you must discontinue use immediately.
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 1.3. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Supplemental terms and conditions or documents that
                          may be posted on our platform from time to time are
                          hereby expressly incorporated herein by reference. We
                          reserve the right, in our sole discretion, to make
                          changes or modifications to these Terms & Conditions
                          from time to time. We will alert you about any changes
                          by updating the “Last updated” date of these Terms &
                          Conditions, and you waive any right to receive
                          specific notice of each such change.
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 1.4. </p>
                        <p className="p-0 m-0">
                          {" "}
                          The information provided on our platform is not
                          intended for distribution to or use by any person or
                          entity in any jurisdiction or country where such
                          distribution or use would be contrary to law or
                          regulation or which would subject us to any
                          registration requirement within such jurisdiction or
                          country. Accordingly, those persons who choose to
                          access our platform from other locations do so on
                          their own initiative and are solely responsible for
                          compliance with local laws, if and to the extent local
                          laws are applicable.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 1.5. </p>
                        <p className="p-0 m-0">
                          {" "}
                          You shall provide your financial data and details only
                          after getting agreed with these terms and conditions
                          to register with our platform to purchase subscription
                          plans.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 1.6. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Our platform is intended for use if you are at least
                          18 years old. Persons under the age of 18 are not
                          permitted to use or register on our platform.
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3">1.7. </p>
                        <p className="p-0 m-0">
                          {" "}
                          All references to “you” or “your” or “user” or
                          “Individual User” as applicable, mean the person that
                          accesses, uses, and/or participates in the Ditinus or
                          its digital Platform in any manner.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 1.8. </p>
                        <p className="p-0 m-0">
                          {" "}
                          The terms and conditions set forth below ("Terms &
                          Conditions" or “Service Level Agreement” or “User
                          Agreement”) and the Privacy Policy (as defined below)
                          constitute a legally binding agreement between we and
                          you. These Terms and Conditions contain provisions
                          that define your limits, legal rights and obligations
                          with respect to your use of and participation in the
                          Ditinus and its digital platform.
                        </p>
                      </div>
                      <div
                        id="service-overview"
                        className="d-flex flex-row  text-WH  mt-3"
                      >
                        <p className="p-0 m-0 me-3"> 1.9. </p>
                        <p className="p-0 m-0">
                          {" "}
                          We reserve the right to add or remove any services or
                          subscription plans at any time on such terms as it
                          deems fit. These Terms and Conditions described below
                          incorporate the Privacy Policy and apply to all users
                          including “Individual User” whether you use our
                          platform or registered on our Platform to create a
                          page.
                        </p>
                      </div>
                    </div>
                    <li

                      className=" fw-bold  responsive-font-size fs-large mb-3"
                    >
                      Service Overview
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        2.1.
                      </p>
                      <p className="p-0 m-0 me-2 mb-3">
                        {" "}
                        We offer a digital platform including our website that
                        enables Users to avail the IT Services being provided
                        herein.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  ">
                      <p
                        className="p-0 m-0 me-3 
                  "
                      >
                        2.2.
                      </p>
                      <p className="p-0 m-0 me-2 mb-3">
                        {" "}
                        Ditinus and its digital platform allows users to get a
                        quote through its portal to avail the following IT
                        Services:
                      </p>
                    </div>

                    <div style={{ paddingLeft: "40px", marginBottom: "20px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 2.2.1. </p>
                        <p className="p-0 m-0"> Software Development</p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 2.2.2. </p>
                        <p className="p-0 m-0"> IT Consulting</p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 2.2.3.</p>
                        <p className="p-0 m-0"> Business Process Services</p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 2.2.4</p>
                        <p className="p-0 m-0"> Agent Based Services</p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 2.2.5.</p>
                        <p className="p-0 m-0"> Digital Marketing</p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 2.2.6.</p>
                        <p className="p-0 m-0"> Design and Development</p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 2.2.7.</p>
                        <p className="p-0 m-0"> Application API and Database</p>
                      </div>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3">
                      <p className="p-0 m-0 me-3"> 2.3. </p>
                      <p className="p-0 m-0">
                        {" "}
                        More particularly, Ditinus and its digital platform is
                        an online venue where users may register themselves in
                        order to get the above referred services.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 ">
                      <p className="p-0 m-0 me-3"> 2.4. </p>
                      <p className="p-0 m-0">
                        {" "}
                        Details of specific services can be found on our website
                        or provided during consultations. Unless explicitly
                        stated otherwise, any new feature that enhances or
                        supplements the Services will be considered part of
                        Ditinus Technology Pvt. Ltd. Services. We reserve the
                        right to modify, suspend, or discontinue the Services
                        (or any portion thereof) at any time, without notice, in
                        the event of non-cooperation, non-payment, or
                        unnecessary delays by the Users. The User agrees that
                        neither they nor any related third party will hold the
                        Company or its suppliers liable for any losses, damages,
                        or consequences arising from such modifications,
                        suspensions, or discontinuations of the Services.
                      </p>
                    </div>
                    <div
                      id="information-submission-get-a-quote"
                      className="d-flex flex-row  text-WH  mt-3 mb-3"
                    >
                      <p className="p-0 m-0 me-3"> 2.5. </p>
                      <p className="p-0 m-0">
                        {" "}
                        As the service facilitation platform, Ditinus and its
                        digital platform does not own, control, or manage any
                        user’s or its content. you are responsible for
                        understanding and complying with all laws, rules,
                        regulations and contracts with your account and Third
                        Parties that apply to your using of our platform and
                        payment process.
                      </p>
                    </div>
                    <li

                      className=" fw-bold  responsive-font-size  fs-large mb-3"
                    >
                      Information Submission & Get a Quote
                    </li>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3">
                      <p className="p-0 m-0 me-3"> 3.1. </p>
                      <p className="p-0 m-0">
                        {" "}
                        In order to use Ditinus and its digital platform or to
                        avail any services provided herein, you may be required
                        to submit certain information as a user on our platform
                        by providing your Personal Identifiable Information
                        (according your profile selection) such as:
                      </p>
                    </div>

                    <div style={{ paddingLeft: "40px", marginBottom: "20px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 3.1.1. </p>
                        <p className="p-0 m-0"> Your Full Name; </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 3.1.2.</p>
                        <p className="p-0 m-0"> Your Email Address; </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3"> 3.1.3.</p>
                        <p className="p-0 m-0"> Your Mobile No.; </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3">3.1.4.</p>
                        <p className="p-0 m-0"> Service Selection;</p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3">
                        <p className="p-0 m-0 me-3">3.1.5.</p>
                        <p className="p-0 m-0">
                          {" "}
                          Any other requisite information.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3">
                      <p className="p-0 m-0 me-3"> 3.2. </p>
                      <p id="payment-terms" className="p-0 m-0">
                        {" "}
                        Use of any personal information you provide to us during
                        submission is governed by our Privacy Policy. You can
                        get a quote by submitting details like name, mobile
                        number, email.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row  text-WH  mt-3 mb-3"
                    >
                      <p className="p-0 m-0 me-3"> 3.3. </p>
                      <p className="p-0 m-0">
                        {" "}
                        Further, you agree to provide all the requisite
                        information correct and accurate in order to provide a
                        quote in relation to the services opted by you.
                      </p>
                    </div>
                    {/* <p>
                      Ditinus Technology Pvt. Ltd. offers a range of IT
                      services, including but not limited to:
                      <ul className="mt-3">
                        <li>Software Development</li>
                        <li>IT Consulting</li>
                        <li>Business Process Services</li>
                        <li>Agent Based Services</li>
                        <li>Digital Marketing</li>
                        <li>Design and Development</li>
                        <li>Application API and Database</li>
                      </ul>
                      <br />
                      Details of specific services can be found on our website
                      or provided during consultations. Unless explicitly stated
                      otherwise, any new feature that enhances or supplements
                      the Services will be considered part of Ditinus Technology
                      Pvt. Ltd. Services. The Company reserves the right to
                      modify, suspend, or discontinue the Services (or any
                      portion thereof) at any time, without notice, in the event
                      of non-cooperation, non-payment, or unnecessary delays by
                      the Client. The Client agrees that neither they nor any
                      related third party will hold the Company or its suppliers
                      liable for any losses, damages, or consequences arising
                      from such modifications, suspensions, or discontinuations
                      of the Services.
                    </p> */}

                    <li

                      className=" fw-bold  responsive-font-size  fs-large mb-3"
                    >
                      Payment Terms
                    </li>
                    <p>
                      The following payments terms shall be applicable whenever
                      a service is being availed or purchased by a client or
                      user:
                    </p>
                    <ol>
                      <div className="d-flex flex-row  text-WH  mt-3 mb-3  fw-bold fs-large ">
                        <p className="p-0 m-0 me-3"> 4.1. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Payment For Web Development Services:
                        </p>
                      </div>
                      <p>
                        If the client chooses to utilize our web development
                        services, they are required to make payments according
                        to the milestones outlined in the contractual agreement.
                        <br />
                        <ul>
                          <li>30% due at the start of the project</li>
                          <li>
                            20% due upon completion of the first milestone
                          </li>
                          <li>
                            20% due upon completion of the second milestone
                          </li>
                          <li>
                            30% due upon final delivery and approval of the
                            project
                          </li>
                        </ul>
                      </p>
                      <div className="d-flex flex-row  text-WH  mt-3 mb-3  fw-bold fs-large ">
                        <p className="p-0 m-0 me-3"> 4.2. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Payment for Business Process Services:
                        </p>
                      </div>
                      <div style={{ marginLeft: "50px" }}>
                        <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                          <p className="p-0 m-0 me-3"> 4.2.1. </p>
                          <p className="p-0 m-0">
                            {" "}
                            When a client opts for our Business Process
                            Services, payment is typically structured on a
                            per-seat basis. This means that clients will be
                            charged according to the number of seats or licenses
                            they require for their operations. Additionally, we
                            require a two-month advance security deposit to
                            ensure a smooth onboarding process and to cover
                            initial operational costs. This payment structure
                            allows us to provide tailored support that aligns
                            with the specific needs of your business while
                            maintaining flexibility as your requirements evolve.
                          </p>
                        </div>

                        <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                          <p className="p-0 m-0 me-3"> 4.2.2. </p>
                          <p className="p-0 m-0">
                            {" "}
                            Our transparent pricing model is designed to help
                            you budget effectively and maximize the value of our
                            services.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3 mb-3 fw-bold fs-large  ">
                        <p className="p-0 m-0 me-3"> 4.3. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Cost Structure for Agent Based Services:
                        </p>
                      </div>
                      <p>
                        When you wish to access our Agent as a Service module,
                        we provide flexible payment options to suit your needs.
                        You can choose a monthly fee for each agent for
                        predictable budgeting and continuous support. We also
                        offer hourly rates for temporary help or project-based
                        fees for specific tasks. This flexibility allows you to
                        pick the payment method that works best for your project
                        and budget while ensuring you receive excellent service
                        from our dedicated agents.
                      </p>

                      <div className="d-flex flex-row  text-WH  mt-3 mb-3 fw-bold fs-large  ">
                        <p className="p-0 m-0 me-3"> 4.4. </p>
                        <p className="p-0 m-0 "> Payment Methods:</p>
                      </div>
                      <p>
                        Clients can choose from a variety of payment methods,
                        including but no limited to:
                      </p>
                      <ul id="no-refund-policy" className="mb-3">
                        <li>Bank Transfer</li>
                        <li>PayPal</li>
                        <li>Credit Cards</li>
                        <li>Debit Cards</li>
                        <li>UPI (Unified Payments Interface)</li>
                      </ul>
                    </ol>

                    <li

                      className="  responsive-font-size  fw-bold fs-large mb-3"
                    >
                      No Refund Policy
                    </li>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 5.1. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        To maintain quality and alignment with client or user’s
                        needs, we implement a rigorous approval process. Clients
                        will have the opportunity to review and approve each
                        project milestone before we proceed to the next stage.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 5.2. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        The Client understands and further undertake that once a
                        project has been delivered and approved by the client, a
                        refund is not applicable. This is due to the fact that
                        services have been provided and accepted by the client
                        as per the agreed-upon milestones.
                      </p>
                    </div>

                    <div
                      id="representations-by-the-user"
                      className="d-flex flex-row  text-WH  mt-3 mb-3  "
                    >
                      <p className="p-0 m-0 me-3"> 5.3. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        More particularly, for any purchase of services made by
                        a client from Ditinus, his/her request of refund shall
                        not be entertained. Under any circumstances, Ditinus and
                        its digital platform shall not issue refund for a
                        purchase of any service made by a client. Ditinus and
                        its digital platform follow a no refund policy.
                      </p>
                    </div>
                    <li

                      className="  responsive-font-size  fw-bold fs-large mb-3"
                    >
                      Representations by the User
                    </li>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 6.1.</p>
                      <p className="p-0 m-0 ">
                        {" "}
                        By using the Ditinus and its digital platform, you
                        represent and warrant that all information you submit
                        will be true, accurate, current, and complete and
                        further you will maintain the accuracy of such
                        information.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 6.2.</p>
                      <p className="p-0 m-0 ">
                        {" "}
                        You hereby agree that our Platform shall be used for
                        lawful purposes only and that you will not violate laws,
                        regulations, ordinances or other such requirements of
                        any applicable Central, State or local government or
                        international law(s).
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 6.3.</p>
                      <p className="p-0 m-0 ">
                        {" "}
                        If you provide any information that is untrue,
                        inaccurate, not current, or incomplete, we have the
                        right to suspend or terminate your use of our platform,
                        further we may refuse any and all current or future use
                        of the Ditinus and its digital platform (or any portion
                        thereof).
                      </p>
                    </div>

                    <div
                      id="reviews-and-submissions-by-users"
                      className="d-flex flex-row  text-WH  mt-3 mb-3  "
                    >
                      <p className="p-0 m-0 me-3"> 6.4.</p>
                      <p className="p-0 m-0 ">
                        {" "}
                        You represent that while communicating, posting, sharing
                        content and while talking with our support executive and
                        grievance redressal officer, you shall not use abusive
                        and derogatory language and/or post any objectionable
                        information that is unlawful, threatening, defamatory,
                        or obscene. If doing so, we reserve the right to suspend
                        the help support service and/or block your access and
                        usage of our Platform, at any time with or without any
                        notice.
                      </p>
                    </div>
                    <li

                      className=" responsive-font-size  fw-bold fs-large mb-3"
                    >
                      Reviews and Submissions by Users
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 7.1.</p>
                      <p className="p-0 m-0 ">
                        {" "}
                        As a client or user, you may be afforded with the
                        opportunity to furnish reciprocal reviews. Your reviews
                        or ratings for Ditinus do not reflect the opinion of us.
                        It is pertinent to state that each and every review
                        posted on our platform is the personal opinion of the
                        user only. We are a neutral platform, which solely
                        provides a digital platform to users.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 7.2.</p>
                      <p className="p-0 m-0 ">
                        {" "}
                        It is noteworthy that Reviews undergo no verification by
                        Ditinus or its digital platform for accuracy, and
                        consequently, there exists a possibility of inaccuracies
                        or misleading content.
                      </p>
                    </div>

                    <div
                      id="changes-to-the-terms"
                      className="d-flex flex-row  text-WH  mt-3 mb-3  "
                    >
                      <p className="p-0 m-0 me-3"> 7.3. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        We reserve the right, at any time and without prior
                        notice, to remove, block, or disable access to any
                        Content, Review or Submission that we, for any reason or
                        no reason, consider to be objectionable, in violation of
                        the Terms & Conditions or otherwise harmful to the
                        Services in our sole discretion. Further, we reserve the
                        right to delete any images and pictures forming part of
                        user’s Content, Review or Submission at our sole
                        discretion.
                      </p>
                    </div>
                    <li

                      className="  responsive-font-size  fw-bold fs-large mb-3"
                    >
                      Changes to the Terms
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 8.1. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        We reserve the right, in its sole discretion, to change,
                        modify, or otherwise amend the Terms of Use, Terms and
                        Conditions for Ditinus, its digital platform and any
                        other documents incorporated by reference herein for
                        complying with legal and regulatory framework and for
                        other legitimate business purposes, at any time, without
                        notice and Ditinus, its digital platform will post the
                        amended Terms of Use of this Document at the website of
                        Ditinus and on other digital platform.
                      </p>
                    </div>
                    <div
                      id="ownership-of-ip-rights"
                      className="d-flex flex-row  text-WH  mt-3 mb-3  "
                    >
                      <p className="p-0 m-0 me-3"> 8.2. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        It is your responsibility to review the Terms of Use for
                        any changes and you are encouraged to check the Terms of
                        Use frequently. Your use of the Platform following any
                        amendment of the Terms and Conditions will signify your
                        assent to and acceptance of any revised Terms &
                        Conditions.
                      </p>
                    </div>
                    <li

                      className="  responsive-font-size  fw-bold fs-large mb-3"
                    >
                      Ownership of IP Rights
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 9.1. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        We grant you a personal, limited, non-exclusive and
                        non-transferable license to access and use the Services
                        only as expressly permitted or in line with these Terms
                        & Conditions. You shall not use the Services for any
                        illegal purpose or in any manner inconsistent with these
                        Terms. You may use information made available through
                        the Services solely for your personal, non-commercial
                        use. Except as otherwise expressly granted to you in
                        writing, we do not grant you any other express or
                        implied right or license to the Services, our Content or
                        our IP Rights.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 9.2. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        Unless otherwise indicated, Ditinus and its digital
                        platform is our proprietary property and all source
                        code, databases, functionality, software, website
                        designs, audio, video, text, photographs, and graphics
                        on our platform (collectively, the “Content”) and the
                        trademarks, service marks, and logos contained therein
                        (the “Marks”) are owned or controlled by us or licensed
                        to us, and are protected by copyright and trademark laws
                        and various other intellectual property rights and
                        unfair competition laws of India, international
                        copyright laws, and international conventions.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 9.3. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        Ditinus and its digital platform owns or holds the
                        Licenses to all Data and Marks on its Website or any
                        other Digital Platform.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 9.4. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        The content on the Platform (exclusive of all Submitted
                        Content), including without limitation, the text,
                        software, scripts, graphics, photos, sounds, music,
                        videos, interactive features and the like ("Data") and
                        the trademarks, service marks and logos contained
                        therein ("Marks"), are owned by Ditinus. Other
                        trademarks, names and logos on Platform are the property
                        of their respective owners.
                      </p>
                    </div>
                    <div
                      id="prohibited-activities"
                      className="d-flex flex-row  text-WH  mt-3 mb-3  "
                    >
                      <p className="p-0 m-0 me-3"> 9.5. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        Data on the Platform is provided to you as is for your
                        information and personal use only and may not be used,
                        copied, reproduced, distributed, transmitted, broadcast,
                        displayed, sold, licensed, or otherwise exploited for
                        any other purposes whatsoever without the prior written
                        consent of the Ditinus.
                      </p>
                    </div>
                    <li

                      className="  responsive-font-size  fw-bold fs-large mb-3"
                    >
                      {" "}
                      Prohibited Activities
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 10.1. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        You may not access or use Ditinus or its digital
                        platform for any purpose other than that for which we
                        make Ditinus available. Ditinus or our digital platform
                        may not be used in connection with any commercial
                        endeavours except those that are specifically endorsed
                        or approved by us.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3  ">
                      <p className="p-0 m-0 me-3"> 10.2. </p>
                      <p className="p-0 m-0 ">
                        {" "}
                        As a user of the Ditinus platform, you agree not to:
                      </p>
                    </div>

                    <div style={{ marginLeft: "50px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                        <p className="p-0 m-0 me-3"> 10.2.1. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Systematically retrieve data or other content from our
                          platform to create or compile, directly or indirectly,
                          a collection, compilation, database, or directory
                          without written permission from us.
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                        <p className="p-0 m-0 me-3"> 10.2.2. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Trick, defraud, or mislead us and users, especially in
                          any attempt to learn sensitive account information
                          such as user passwords.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                        <p className="p-0 m-0 me-3"> 10.2.3. </p>
                        <p id="term-and-termination" className="p-0 m-0">
                          {" "}
                          Circumvent, disable, or otherwise interfere with
                          security-related features of the Ditinus, including
                          features that prevent or restrict the use or copying
                          of any Content or enforce limitations on the use of
                          the Ditinus and/or the Content contained therein.
                        </p>
                      </div>
                      <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                        <p className="p-0 m-0 me-3"> 10.2.4. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Make improper use of our support services or submit
                          false reports of abuse or misconduct.
                        </p>
                      </div>

                      <div

                        className="d-flex flex-row  text-WH  mt-3 mb-3   "
                      >
                        <p className="p-0 m-0 me-3"> 10.2.5. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Use the Ditinus in a manner inconsistent with any
                          applicable laws or regulations.
                        </p>
                      </div>
                    </div>
                    <li

                      className=" responsive-font-size  fw-bold fs-large mb-3"
                    >
                      {" "}
                      Term and Termination
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 11.1. </p>
                      <p className="p-0 m-0">
                        {" "}
                        The agreement between you and our platform reflected by
                        these Terms & Conditions remains in effect until either
                        you or we terminate the agreement in accordance with
                        these Terms.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3">11.2. </p>
                      <p className="p-0 m-0">
                        {" "}
                        Termination by Ditinus: You hereby acknowledge and agree
                        that Ditinus in its sole and absolute discretion, has
                        the right (but not the obligation) to terminate your
                        access, block your email or IP address, cancel the
                        Ditinus or otherwise terminate your access to or
                        participation in the use of the Ditinus (or any part
                        thereof), or remove and discard any Submitted Content on
                        the Platform ("Termination of Service"), immediately and
                        without notice.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 11.3. </p>
                      <p className="p-0 m-0">
                        {" "}
                        We may also terminate this agreement immediately and
                        without prior notice and stop providing access to the
                        Ditinus if:
                      </p>
                    </div>

                    <div style={{ marginLeft: "50px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                        <p className="p-0 m-0 me-3"> 11.3.1. </p>
                        <p className="p-0 m-0">
                          {" "}
                          you materially breach these Terms or Additional Legal
                          Terms, or Policies;
                        </p>
                      </div>

                      <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                        <p className="p-0 m-0 me-3"> 11.3.2.</p>
                        <p className="p-0 m-0">
                          {" "}
                          you violate applicable laws, or such action is
                          necessary to protect the personal safety of Ditinus,
                          its members, or third parties (for example in the case
                          of fraudulent behaviour of a member).
                        </p>
                      </div>
                    </div>

                    <div
                      id="grievances-redressals-mechanism"
                      className="d-flex flex-row  text-WH  mt-3 mb-3   "
                    >
                      <p className="p-0 m-0 me-3"> 11.4. </p>
                      <p className="p-0 m-0">
                        {" "}
                        Termination by You: Users reserves the right to
                        terminate the utilization of the Ditinus and its digital
                        platform at their discretion, either before engaging in
                        any purchasing or availing of services provided by
                        Ditinus and its digital platform. You may terminate this
                        agreement at any time by sending us an email.
                      </p>
                    </div>
                    <li


                      className="    responsive-font-size  fw-bold fs-large mb-3"
                    >
                      Grievances Redressals Mechanism
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 12.1. </p>
                      <p className="p-0 m-0">
                        {" "}
                        If you have any issues in relation to the practices of
                        the Ditinus and its digital platform, you may notify us
                        and seek resolution of your complaints by sending a mail
                        to our support executive at{" "}
                        <Link to="mailto:info@ditinustechnology.com">
                          {" "}
                          info@ditinustechnology.com
                        </Link>
                        .
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 12.2. </p>
                      <p className="p-0 m-0">
                        {" "}
                        In case you do not receive a satisfactory response from
                        our support executive, you can escalate the matter to
                        our Grievance Redressal Officer on the below given
                        information:
                      </p>
                    </div>

                    <div style={{ marginLeft: "50px" }}>
                      <div className="d-flex flex-row  text-WH  mt-3 mb-3  fw-bold fs-large  ">
                        <p className="p-0 m-0 me-3 "> 12.2.1. </p>
                        <p className="p-0 m-0">
                          {" "}
                          Details of the Grievance Officer:
                        </p>
                      </div>
                      <ul className="mb-3">
                        <li>
                          Email Address of the Grievance Officer :
                          <p className="text-wrap p-0">
                            <Link to="mailto:grievance.officer@ditinustechnology.com">
                              {" "}
                              grievance.officer@ditinustechnology.com
                            </Link>{" "}</p>
                        </li>
                      </ul>
                    </div>

                    <div id="our-role-disclaimer" className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 12.3. </p>
                      <p className="p-0 m-0">
                        {" "}
                        You expressly agree to cooperate in good faith, furnish
                        any requested information, execute necessary documents,
                        and undertake reasonable actions concerning your Claims,
                        complaints, or any other claims arising in connection
                        with your provisions.
                      </p>
                    </div>

                    <div

                      className="d-flex flex-row  text-WH  mt-3 mb-3   "
                    >
                      <p className="p-0 m-0 me-3"> 12.4.</p>
                      <p className="p-0 m-0">
                        {" "}
                        Ditinus and its digital platform's decisions on Claims
                        do not affect your contractual or statutory rights. We
                        are not liable for any damages and shall not be involved
                        in court proceedings related to such damages.
                      </p>
                    </div>
                    <li

                      className="    responsive-font-size fw-bold fs-large mb-3"
                    >
                      {" "}
                      Our Role & Disclaimer
                    </li>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 13.1.</p>
                      <p className="p-0 m-0">
                        {" "}
                        Ditinus provides an online platform or venue that
                        enables users to avail the IT services being provided
                        herein and to get a quote for the services by providing
                        required details. The user agree that our Services are
                        provided on an “as-is” and “as-available” basis. Ditinus
                        Technology Pvt. Ltd. makes no warranties or
                        representations, express or implied, regarding the
                        accuracy, reliability, or availability of our Services.
                      </p>
                    </div>

                    <div
                      id="other-applicable-policies"
                      className="d-flex flex-row  text-WH  mt-3 mb-3   "
                    >
                      <p className="p-0 m-0 me-3"> 13.2. </p>
                      <p className="p-0 m-0">
                        {" "}
                        Ditinus and its digital platform does not endorse or
                        warrant user’s content or third party’s services. We are
                        not responsible for internet outages, disruptions, or
                        temporary platform unavailability. We may restrict
                        platform availability for maintenance, security, or
                        capacity reasons, considering involving parties'
                        legitimate interests.
                      </p>
                    </div>

                    <li

                      className="    responsive-font-size  fw-bold fs-large mb-3"
                    >
                      {" "}
                      Other Applicable Policies
                    </li>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 14.1. </p>
                      <p className="p-0 m-0">
                        {" "}
                        We care about data privacy, security and smooth
                        facilitation of the services by using our platform. By
                        using Ditinus and its digital platform, you agree to be
                        bound by our ‘Privacy Policy’, posted on the Ditinus and
                        its digital platform along with this Terms of Use
                        document. These all policies are in consonance with
                        these Terms of Use.
                      </p>
                    </div>
                    <div

                      className="d-flex flex-row  text-WH  mt-3 mb-3   "
                    >
                      <p className="p-0 m-0 me-3"> 14.2. </p>
                      <p className="p-0 m-0">
                        {" "}
                        You represent that you have read, understood and agreed
                        to our all-above-mentioned applicable policies.
                      </p>
                    </div>

                    <li id="dispute-resolution"

                      className="    responsive-font-size  fw-bold fs-large mb-3"
                    >
                      {" "}
                      Dispute Resolution
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 15.1. </p>
                      <p className="p-0 m-0">
                        {" "}
                        If a dispute arises between you and Ditinus, in such
                        case, you and Ditinus hereby agree to resolve any claim
                        or controversy at law and equity that arises out of
                        these Terms & Conditions through Mutual Discussion.
                      </p>
                    </div>

                    <div
                      id="limitation-of-liability"
                      className="d-flex flex-row  text-WH  mt-3 mb-3   "
                    >
                      <p className="p-0 m-0 me-3"> 15.2. </p>
                      <p className="p-0 m-0">
                        {" "}
                        In case of non-resolution through Mutual Discussion, the
                        dispute shall be referred to the Arbitrator appointed by
                        Ditinus and finally resolved through Arbitration
                        following the procedure mentioned in the Arbitration and
                        Conciliation Act, 1996 and applicable rules.
                      </p>
                    </div>
                    <li

                      className="   responsive-font-size  fw-bold fs-large mb-3"
                    >
                      {" "}
                      Limitation of Liability
                    </li>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 16.1. </p>
                      <p className="p-0 m-0">
                        {" "}
                        Ditinus and its digital platform will not be liable for
                        any direct, indirect, incidental, consequential or
                        exemplary loss or damages which may be incurred by you
                        as a result of using our Resources, or making a purchase
                        using our website or as a result of any changes, data
                        loss or corruption, cancellation, loss of access, or
                        downtime to the full extent that applicable limitation
                        of liability laws apply.
                      </p>
                    </div>
                    <div
                      id="indemnification"
                      className="d-flex flex-row  text-WH  mt-3 mb-3   "
                    >
                      <p className="p-0 m-0 me-3"> 16.2. </p>
                      <p className="p-0 m-0">
                        {" "}
                        In no event shall Ditinus, or its respective officers,
                        managers, members, directors, employees, successors,
                        assigns, subsidiaries, affiliates, suppliers, attorneys
                        or agents, be liable to you for any direct, indirect,
                        incidental, special, punitive, consequential or
                        exemplary damages (including but not limited to loss of
                        business, revenue, profits, use, data or other economic
                        advantage).
                      </p>
                    </div>
                    <li

                      className="   responsive-font-size  fw-bold fs-large mb-3"
                    >
                      {" "}
                      Indemnification
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 17.1. </p>
                      <p className="p-0 m-0">
                        You agree to defend, indemnify and hold harmless the
                        Ditinus, its digital platform, its employees, directors,
                        officers, agents and their successors and assigns from
                        and against any and all claims, liabilities, damages,
                        losses, costs and expenses, including attorney’s fees,
                        caused by or arising out of claims based upon a breach
                        of any warranty, representation or undertaking in this
                        Service Level Agreement, or arising out of a violation
                        of any applicable law (including but not limited in
                        relation to intellectual property rights, payment of
                        statutory dues and taxes, claims of libel, defamation,
                        violation of rights of privacy or publicity, loss of
                        service by other subscribers and infringement of
                        intellectual property or other rights). This clause
                        shall survive the expiry or termination of this Service
                        Level Agreement.
                      </p>
                    </div>
                    <li


                      className="   responsive-font-size  fw-bold fs-large mb-3"
                    >
                      {" "}
                      Governing Law
                    </li>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 18.1. </p>
                      <p id="general-terms" className="p-0 m-0">
                        These Terms shall be governed by and defined following
                        the laws of India. Ditinus Technology Private Limited
                        and yourself irrevocably consent that the courts of
                        India at Mohali, Punjab shall have exclusive
                        jurisdiction to resolve any dispute which may arise in
                        connection with these terms.
                      </p>
                    </div>
                    <li

                      className="   responsive-font-size  fw-bold fs-large mb-3"
                    >
                      {" "}
                      General Terms
                    </li>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 19.1. </p>
                      <p className="p-0 m-0">
                        <span className="fw-bold fs-large">
                          {" "}
                          Entire Agreement:
                        </span>{" "}
                        The Terms and Conditions, together with the ‘Privacy
                        Policy’ and ‘Terms of Use’, shall constitute the entire
                        agreement between you and us concerning the Services of
                        Ditinus and its digital platform.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 19.2. </p>
                      <p className="p-0 m-0">
                        <span className="fw-bold fs-large">
                          {" "}
                          Externals Links:
                        </span>{" "}
                        This site may contain hyperlinks to external websites
                        that are not maintained by or affiliated with the
                        Company. These hyperlinks are provided as a convenience
                        to users and do not imply sponsorship or endorsement by
                        the Company. The Company has not reviewed all of these
                        external sites and is not responsible for their content.
                        Accessing these hyperlinks is at the Client’s own risk,
                        and the Company makes no representations or warranties
                        regarding the content, completeness, or accuracy of the
                        hyperlinks or the sites they connect to. Furthermore,
                        the inclusion of a hyperlink to a third-party site does
                        not imply the Company’s endorsement of that site.
                      </p>
                    </div>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 19.3. </p>
                      <p className="p-0 m-0">
                        <span className="fw-bold fs-large"> Waiver:</span> No
                        failure or delay by us in exercising any right, power or
                        privilege under these Terms & Conditions shall operate
                        as a waiver of such right or acceptance of any variation
                        of the Terms and nor shall any single or partial
                        exercise by either party of any right, power or
                        privilege preclude any further exercise of that right or
                        the exercise of any other right, power or privilege.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3">19.4. </p>
                      <p className="p-0 m-0">
                        <span className="fw-bold fs-large"> Severability:</span>{" "}
                        If any provision of these Terms is deemed unlawful,
                        invalid, or unenforceable by a judicial court for any
                        reason, then that provision shall be deemed severed from
                        these Terms, and the remainder of the Terms shall
                        continue in full force and effect.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 19.5.</p>
                      <p id="contact-us" className="p-0 m-0">
                        <span className="fw-bold fs-large">
                          Partnership or Agency:
                        </span>{" "}
                        None of the provisions of these Terms shall be deemed to
                        constitute a partnership or agency between you and
                        Ditinus and you shall have no authority to bind Ditinus in
                        any form or manner, whatsoever.
                      </p>
                    </div>

                    <li className="   responsive-font-size  fw-bold fs-large mb-3"> Contact us</li>

                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 20.1. </p>
                      <p className="p-0 m-0">
                        If you have any questions or comments about these Terms
                        & Conditions, you can contact us at the below mentioned
                        address.
                      </p>
                    </div>
                    <div className="d-flex flex-row  text-WH  mt-3 mb-3   ">
                      <p className="p-0 m-0 me-3"> 20.2. </p>
                      <p className="p-0 m-0">
                        Any notices or other communications required or
                        permitted in terms of these presents shall be deemed to
                        have been duly served if, (a) delivered in person; or
                        (b) sent by registered or certified mail, return receipt
                        requested, or (c) email; and addressed as follows:
                      </p>
                    </div>
                    <div style={{ marginLeft: "50px" }}>
                      {" "}
                      <p className="p-0 m-0">
                        Ditinus Technology Private Limited
                      </p>
                      <p className="p-0 m-0">
                        Plot E-302, PH-8, Vista Tower, Mohali, Punjab – 160062
                      </p>
                      <p className="p-0 m-0">
                        <p className="p-0 m-0">
                          <p className="p-0 m-0">
                            {" "}
                            <Link to="mailto:grievance.officer@ditinustechnology.com">
                              {" "}
                              grievance.officer@ditinustechnology.com
                            </Link>
                          </p>
                        </p>
                      </p>
                    </div>
                  </ol>
                </div>
              </div>
              {!isMobile && (
                <div className="col-4">
                  <nav className="text-white w-full position-sticky privacy_link">
                    <div>
                      {/* <ul className="list-unstyled text-white">
                        {sections.map(({ id, title }) => (
                          <li key={id}>
                            <button
                              style={{ fontSize: "14px" }}
                              onClick={() => scrollToSection(id)}
                              className={`w-100 text-start btn   ${
                                activeSection === id
                                  ? "text-primary"
                                  : "text-white"
                              }`}
                            >
                              {title}
                            </button>
                          </li>
                        ))}
                      </ul> */}


                      <Container fluid className="vertical-timeline-terms">
                        <Col className="timeline-nav">
                          <Nav className="flex-column">
                            {sections?.map((item, index) => (
                              <Nav.Link
                                key={item?.id}
                                ref={(ref) => {
                                  if (activeSection === item?.id && ref) {
                                    ref.scrollIntoView({
                                      behavior: "smooth",
                                      block: "nearest",
                                    });
                                  }
                                }}
                                className={activeSection === item?.id ? "active" : ""}
                                onClick={() => scrollToSection(item?.id)}
                              >
                                <span className="timeline-number me-2">{index + 1}</span>
                                <span className="me-3">{item?.title}</span>
                              </Nav.Link>
                            ))}
                          </Nav>
                        </Col>
                      </Container>


                    </div>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Index;
