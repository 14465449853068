import "./index.css";
import "./assets/css/bootstrap.css";
import "./assets/css/animation.css";
import "./assets/css/style.css";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs/index";
import Services from "./Pages/Services";
import Blogs from "./Pages/Blogs";
import ContactUs from "./Pages/ContactUs";
import SingleBlog from "./Pages/Blogs/SingleBlog";
import UIUX from "./Pages/UIUX";
import DigitalMaketing from "./Pages/DigitalMarketing";
import Development from "./Pages/Development";
import Training from "./Pages/Training";
import Graphics from "./Pages/Graphics";
import TermsConditions from "./Pages/TermsConditions";
import Career from "./Pages/Career";
import ApplyNow from "./Pages/Career/ApplyNow";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import HireADeveloper from "./Pages/HireADeveloper";
import CaseStudy from "./Pages/CaseStudy/SingleCaseStudy";
import KpoBpo from "./Pages/KpoBpo";
import CaseStudys from "./Pages/CaseStudy";
import GetAQute from "./Components/GetAQoute";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import axios from "axios";
import { useEffect, useState } from "react";
import StickyBarWithIcons from "./Components/FloatBar";
import NotFound from "./Pages/NotFound/NotFound";

const App = () => {
  const [isBlogs, setIsBlogs] = useState<boolean>(false);

  const location = useLocation();

  // Define pages where header and footer should be hidden
  const unhideHeaderFooter =

    location.pathname === "" ||
    location.pathname === "/" ||
    location.pathname === "/about-us" ||
    location.pathname === "/services" ||
    location.pathname === "/contact-us" ||
    location.pathname === "/ui-ux-design-company" ||
    location.pathname === "/digital-marketing-company" ||
    location.pathname === "/software-development-company" ||
    location.pathname === "/graphics-design-agency" ||
    location.pathname === "/business-process-services" ||
    location.pathname === "/hire-a-developer" ||
    location.pathname === "/training" ||
    location.pathname === "/career" ||
    location.pathname === "/case-studies" ||
    location.pathname === "/privacy-policy" ||
    location.pathname === "/terms-conditions" ||
    location.pathname === "/blogs" ||


    location.pathname.includes("/career/") ||
    location.pathname.includes("/blog/") ||
    location.pathname.includes("/case-study/");

  const show =
    location.pathname !== "/contact-us" &&
    location.pathname !== "/training" &&
    location.pathname !== "/business-process-services" &&
    !location.pathname.includes("/career");

  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  const fetchBlogs = async () => {
    const response = await api.get(`/blogs?page=1`);
    setIsBlogs(response?.data?.data?.length > 0);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <StickyBarWithIcons />
      <ScrollToTop />
      {/* Conditionally render the header and footer */}
      {unhideHeaderFooter && <Header />}

      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/ui-ux-design-company" element={<UIUX />} />
        <Route path="/digital-marketing-company" element={<DigitalMaketing />} />
        <Route path="/software-development-company" element={<Development />} />
        <Route path="/graphics-design-agency" element={<Graphics />} />
        <Route path="/business-process-services" element={<KpoBpo />} />
        <Route path="/hire-a-developer" element={<HireADeveloper />} />
        <Route path="/training" element={<Training />} />
        <Route path="/career/:id" element={<ApplyNow />} />
        <Route path="/career" element={<Career />} />
        <Route path="/case-study/:slug" element={<CaseStudy />} />
        <Route path="/case-studies" element={<CaseStudys />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        {isBlogs && <Route path="/blogs" element={<Blogs />} />}
        {isBlogs && <Route path="/blog/:slug" element={<SingleBlog />} />}
        <Route path="*" element={<NotFound />} />

      </Routes>

      {/* Conditionally render the GetAQute component and Footer */}
      {show && unhideHeaderFooter && <GetAQute />}

      {unhideHeaderFooter && <Footer />}
    </>
  );
};

export default App;
